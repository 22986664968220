import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { sendInvite } from "../../../redux/slices/invites";

type InviteUserFormProps = {
  handleClose: () => void;
};

const useStyles = makeStyles({
  headerText: {
    fontSize: "1.5rem",
    textAlign: "center",
  },
  descriptionText: {
    fontSize: "0.8rem",
    color: "#777",
    margin: "1.5rem",
  },
  formField: {
    margin: "0.75rem auto",
  },
  submitButton: {
    marginTop: "1rem",
  },
});

const InviteUserForm: React.FC<InviteUserFormProps> = ({ handleClose }) => {
  const classes = useStyles();

  const invites = useAppSelector((state) => state.invites);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      invitedById: user?.id.toString() || "",
      userRoleId: "",
      email: "",
      firstName: "",
      lastName: "",
    },
    validationSchema: Yup.object({
      userRoleId: Yup.string().required("User type is required."),
      email: Yup.string()
        .email("Must be a valid email address.")
        .required("Email address is required."),
      firstName: Yup.string(),
      lastName: Yup.string(),
    }),
    onSubmit: async (values) => {
      await dispatch(sendInvite(values));
      handleClose();
    },
  });

  return (
    <div>
      <Typography variant="h1" className={classes.headerText}>
        Invite New User
      </Typography>
      <Typography className={classes.descriptionText}>
        Fill out the form below to invite a user. They will receive an email
        with a link to set up their account.
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          select
          variant="outlined"
          className={classes.formField}
          label="User type"
          error={formik.touched.userRoleId && Boolean(formik.errors.userRoleId)}
          helperText={formik.touched.userRoleId && formik.errors.userRoleId}
          {...formik.getFieldProps("userRoleId")}
          fullWidth
          required
        >
          <MenuItem value="2">Administrator</MenuItem>
          <MenuItem value="4">Member</MenuItem>
        </TextField>
        <TextField
          variant="outlined"
          className={classes.formField}
          label="Email address"
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          {...formik.getFieldProps("email")}
          fullWidth
          required
        />
        <TextField
          variant="outlined"
          className={classes.formField}
          label="First name"
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
          {...formik.getFieldProps("firstName")}
          fullWidth
        />
        <TextField
          variant="outlined"
          className={classes.formField}
          label="Last name"
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          {...formik.getFieldProps("lastName")}
          fullWidth
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={classes.submitButton}
          disabled={invites.status === "sending"}
          size="large"
        >
          {invites.status === "sending" ? "Submitting..." : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default InviteUserForm;
