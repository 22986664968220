import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import {
  getUserFromToken,
  login as authLogin,
  logout as authLogout,
} from "../../services/auth";
import { LoginType, User } from "../../types/auth";

interface AuthState {
  user: User | null;
  status: "idle" | "loading" | "loggingIn" | "succeeded" | "failed";
  error: null | string;
}

const initialState: AuthState = {
  user: null,
  status: "idle",
  error: null,
};

export const checkAuth = createAsyncThunk(
  "auth/checkToken",
  async (_, { rejectWithValue }) => {
    try {
      return await getUserFromToken();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", () => {
  authLogout();
});

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }: LoginType, { rejectWithValue }) => {
    try {
      return await authLogin(email, password);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    updateShowMotd(state, action: PayloadAction<boolean>) {
      (state.user as User).show_motd = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAuth.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        checkAuth.fulfilled,
        (state, action: PayloadAction<User | null>) => {
          state.status = "succeeded";
          state.user = action.payload;
        }
      )
      .addCase(checkAuth.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.status = "idle";
        state.error = null;
      })
      .addCase(login.pending, (state) => {
        state.status = "loggingIn";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

// Export any needed selectors
export const selectUser = (state: RootState) => state.auth.user;

// Export actions
export const { updateUser, updateShowMotd } = authSlice.actions;

// Export the generated reducer
export default authSlice.reducer;
