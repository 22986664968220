import React, { useState } from "react";
import clsx from "clsx";
import axios from "axios";
import config from "../../config";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { Post } from "../../types/posts";

type ReactionsProps = {
  className?: string;
  post: Post;
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    marginLeft: -10,
    fontSize: "20px",
  },
  likedButton: {
    color: colors.red[600],
  },
}));

const Reactions: React.FC<ReactionsProps> = ({ className, post, ...rest }) => {
  const classes = useStyles();
  const [isLiked, setLiked] = useState<boolean>(post.is_liked);
  const [likes, setLikes] = useState<number>(post.like_count);

  const addLike = async () => {
    axios
      .post(
        `${config.API_URL}/posts/likes`,
        { postId: post.id, action: "add" },
        config.getAxiosJwtHeader()
      )
      .then((result) => {
        if (result && result.status === 201) {
          console.log("Post like successful.");
        } else {
          console.error("Post like unsuccessful.");
        }
      })
      .catch((e) => {
        console.error(e.response);
      });
  };

  const deleteLike = async () => {
    axios
      .post(
        `${config.API_URL}/posts/likes`,
        { postId: post.id, action: "delete" },
        config.getAxiosJwtHeader()
      )
      .then((result) => {
        if (result && result.status === 201) {
          console.log("Post unlike successful.");
        } else {
          console.error("Post unlike unsuccessful.");
        }
      })
      .catch((e) => {
        console.error(e.response);
      });
  };

  const handleLike = (): void => {
    setLiked(true);
    setLikes((prevLikes) => Number(prevLikes) + 1);
    addLike();
  };

  const handleUnlike = (): void => {
    setLiked(false);
    setLikes((prevLikes) => Number(prevLikes) - 1);
    deleteLike();
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {isLiked ? (
        <Tooltip title="Unlike">
          <IconButton
            className={clsx(classes.likedButton, classes.iconButton)}
            onClick={handleUnlike}
          >
            <FavoriteIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Like">
          <IconButton onClick={handleLike} className={classes.iconButton}>
            <FavoriteBorderIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
      <Typography color="textSecondary" variant="subtitle2">
        {likes}
      </Typography>
      <Box flexGrow={1} />
    </div>
  );
};

export default Reactions;
