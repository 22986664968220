import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import axios from "axios";
import config from "../config";
import PageHeader from "../components/PageHeader";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ApplicationViewer from "../components/Application/ApplicationViewer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import useKeyPress from "../hooks/useKeyPress";
import { useAppSelector } from "../redux/hooks";

type ApplicationRecordType = {
  id: number;
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number: string;
  address_1: string;
  address_2?: string | null;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  submission_primary: string;
  submission_secondary?: string | null;
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    maxWidth: 1200,
    height: "100%",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  paginationRow: {
    display: "flex",
    width: "100%",
    height: 60,
    alignItems: "center",
    justifyContent: "space-between",
  },
  arrowIcon: {
    fontSize: 50,
    color: "black",
  },
  viewerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ApplicationsPage: React.FC = () => {
  const classes = useStyles();
  const auth = useAppSelector((state) => state.auth);
  const history = useHistory();

  const pageLeft = useKeyPress("ArrowLeft");
  const pageRight = useKeyPress("ArrowRight");

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [isDenied, setIsDenied] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [applicationIndex, setApplicationIndex] = useState<number>(0);
  const [applications, setApplications] = useState<
    null | ApplicationRecordType[]
  >(null);

  const handleApprove = () => {
    setIsSubmitting(true);
    applications &&
      axios
        .post(
          `${config.API_URL}/applications/approval`,
          {
            id: applications[applicationIndex].id,
          },
          config.getAxiosJwtHeader()
        )
        .then((response) => {
          if (response.status === 200) {
            console.log("Success!");
            handleApproveSuccess();
          }
        })
        .catch((e) => {
          console.error(e.response);
          setIsSubmitting(false);
          setIsError(true);
        });
  };

  const handleApproveSuccess = () => {
    setIsSubmitting(false);
    setApplications(null);
    setIsApproved(true);
    setIsLoading(true);
    getApplications();
  };

  const handleDenySuccess = () => {
    setIsSubmitting(false);
    setApplications(null);
    setIsDenied(true);
    setIsLoading(true);
    getApplications();
  };

  const handleDeny = () => {
    setIsSubmitting(true);
    applications &&
      axios
        .delete(
          `${config.API_URL}/applications/${applications[applicationIndex].id}`,
          config.getAxiosJwtHeader()
        )
        .then((response) => {
          if (response.status === 200) {
            handleDenySuccess();
          }
        })
        .catch((e) => {
          console.error(e.response);
          setIsSubmitting(false);
          setIsError(true);
        });
  };

  useEffect(() => {
    setTimeout(() => {
      isApproved && setIsApproved(false);
      isDenied && setIsDenied(false);
    }, 3000);
  });

  useEffect(() => {
    if (applications) {
      pageLeft &&
        applicationIndex !== 0 &&
        setApplicationIndex(applicationIndex - 1);

      pageRight &&
        applicationIndex !== applications.length - 1 &&
        setApplicationIndex(applicationIndex + 1);
    }
    // eslint-disable-next-line
  }, [pageLeft, pageRight]);

  const getApplications = () => {
    axios
      .get(`${config.API_URL}/applications`, config.getAxiosJwtHeader())
      .then((response) => {
        if (response && response.status === 200) {
          setApplications(response.data);
          setIsLoading(false);
        } else {
          console.error(response.status);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setIsLoading(false);
        } else {
          console.error(e.response);
          setIsLoading(false);
        }
      });
  };

  // Get applications on first render
  useEffect(() => {
    if (auth.user?.role === "Member") {
      history.push("/");
    } else {
      getApplications();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <PageHeader text="Verify Applications" />
      {isLoading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : applications ? (
        <>
          <div className={classes.paginationRow} style={{ marginBottom: 20 }}>
            <IconButton
              className={classes.arrowIcon}
              disabled={applicationIndex === 0}
              onClick={() => setApplicationIndex(applicationIndex - 1)}
            >
              <NavigateBeforeIcon fontSize="inherit" />
            </IconButton>
            <Typography>
              {applicationIndex + 1} of {applications.length}
            </Typography>
            <IconButton
              className={classes.arrowIcon}
              disabled={applicationIndex === applications.length - 1}
              onClick={() => setApplicationIndex(applicationIndex + 1)}
            >
              <NavigateNextIcon fontSize="inherit" />
            </IconButton>
          </div>
          <div className={classes.viewerContainer}>
            <ApplicationViewer
              appData={applications[applicationIndex]}
              approveCallback={handleApprove}
              denyCallback={handleDeny}
              disabled={isSubmitting}
            />
            {isError && (
              <div style={{ width: 400, margin: "20px auto" }}>
                <Alert severity="error">
                  There was a problem during the submission.
                </Alert>
              </div>
            )}
          </div>
          {isApproved && (
            <Snackbar open={isApproved}>
              <Alert severity="success">Application approved!</Alert>
            </Snackbar>
          )}
          {isDenied && (
            <Snackbar open={isDenied}>
              <Alert severity="error">Application denied!</Alert>
            </Snackbar>
          )}
        </>
      ) : (
        <Typography style={{ textAlign: "center", marginTop: "1rem" }}>
          No applications to verify.
        </Typography>
      )}
    </div>
  );
};

export default ApplicationsPage;
