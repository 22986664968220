import React from "react";
import { NavLink } from "react-router-dom";
import {
  Backdrop,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import ContactsIcon from "@material-ui/icons/Contacts";
import InfoIcon from "@material-ui/icons/Info";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import bg from "../assets/nav-bg.png";
import { useAppSelector } from "../redux/hooks";

type NavSidebarProps = {
  isOpen: boolean;
  toggleIsOpen: () => void;
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: 89,
    backgroundImage: `url(${bg})`,
  },
}));

const navList = (role: string) => {
  const adminNavListItems = [
    {
      name: "Home",
      to: "/",
      icon: <HomeIcon />,
    },
    { name: "Media Gallery", to: "/media", icon: <VideoLibraryIcon /> },
    { name: "Upload Media", to: "/media-upload", icon: <CloudUploadIcon /> },
    { name: "Applications", to: "/applications", icon: <ContactsIcon /> },
    { name: "User Invites", to: "/invites", icon: <PersonAddIcon /> },
  ];

  const memberNavListItems = [
    {
      name: "Home",
      to: "/",
      icon: <HomeIcon />,
    },
    { name: "Media Gallery", to: "/media", icon: <VideoLibraryIcon /> },
    { name: "About", to: "/about", icon: <InfoIcon /> },
  ];

  const adminNavList = adminNavListItems.map((item, index) => {
    return (
      <ListItem
        button
        key={index}
        component={NavLink}
        to={item.to}
        activeClassName="Mui-selected"
        exact
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.name} />
      </ListItem>
    );
  });

  const memberNavList = memberNavListItems.map((item, index) => {
    return (
      <ListItem
        button
        key={index}
        component={NavLink}
        to={item.to}
        activeClassName="Mui-selected"
        exact
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.name} />
      </ListItem>
    );
  });

  return role === "Member" ? memberNavList : adminNavList;
};

const NavSidebar: React.FC<NavSidebarProps> = ({ isOpen, toggleIsOpen }) => {
  const classes = useStyles();
  const auth = useAppSelector((state) => state.auth);

  return (
    <>
      <Backdrop
        open={isOpen}
        className={classes.backdrop}
        onClick={toggleIsOpen}
      />
      <Drawer
        variant="persistent"
        anchor="left"
        classes={{ paper: classes.drawerPaper }}
        open={isOpen}
      >
        <List component="nav">{navList(auth.user?.role as string)}</List>
      </Drawer>
    </>
  );
};

export default NavSidebar;
