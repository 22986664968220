import React, { useEffect } from "react";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AppRouter from "./routes/AppRouter";
import LoadingSpinner from "./components/LoadingSpinner";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { checkAuth } from "./redux/slices/auth";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      contained: {
        transition: "all .2s ease-in-out",
        "&&:hover": {
          transform: "scale(1.02)",
          backgroundColor: "#333",
        },
      },
    },
  },
  palette: {
    primary: {
      light: "#F8F8F8",
      dark: "#1A1A1A",
      main: "#5368BB",
    },
    secondary: {
      light: "#F4F6F8",
      dark: "#2E3039",
      main: "#CF453D",
    },
  },
});

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth);

  useEffect(() => {
    const checkAuthState = async () => {
      await dispatch(checkAuth());
    };

    if (user.status === "idle") {
      checkAuthState();
    }
  }, [user, dispatch]);

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        {user.status === "loading" ? <LoadingSpinner /> : <AppRouter />}
      </ThemeProvider>
    </>
  );
};

export default App;
