import React from "react";
import { makeStyles } from "@material-ui/core";
import PageHeader from "../components/PageHeader";
import ProfileForm from "../components/Forms/ProfileForm";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    maxWidth: 1200,
    height: "100%",
  },
}));

const ProfilePage: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PageHeader text="Your Profile" />
      <ProfileForm />
    </div>
  );
};

export default ProfilePage;
