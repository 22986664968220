import React, { useState } from "react";
import type { FC } from "react";
import clsx from "clsx";
import axios from "axios";
import config from "../../config";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {
  Avatar,
  Box,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import Linkify from "react-linkify";
import { useAppSelector } from "../../redux/hooks";
import ConfirmationDialog from "../ConfirmationDialog";
import { PostComment } from "../../types/posts";

type CommentProps = {
  className?: string;
  comment: PostComment;
  refreshCallback(): void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  bubble: {
    borderRadius: theme.shape.borderRadius,
  },
  deleteLink: {
    marginTop: "0.2rem",
    display: "inline-block",
    fontSize: "0.9rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const Comment: FC<CommentProps> = ({
  className,
  comment,
  refreshCallback,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const auth = useAppSelector((state) => state.auth);

  const confirmDelete = () => {
    setConfirmDeleteOpen(true);
  };

  const cancelDelete = () => {
    setConfirmDeleteOpen(false);
  };

  const handleDelete = () => {
    setConfirmDeleteOpen(false);

    axios
      .delete(
        `${config.API_URL}/posts/comments/${comment.id}`,
        config.getAxiosJwtHeader()
      )
      .then((response) => {
        if (response && response.status === 200) {
          refreshCallback();
        }
      })
      .catch((e) => console.error(e.response));
  };

  const canDelete = () => {
    if (auth.user?.id === comment.author_id || auth.user?.role !== "Member") {
      return true;
    }

    return false;
  };

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Avatar
          alt="Person"
          src={comment.avatar ? config.CDN_AVATAR_URL + comment.avatar : ""}
        />
        <Box
          flexGrow={1}
          p={2}
          ml={2}
          bgcolor={theme.palette.secondary.light}
          className={classes.bubble}
        >
          <Box display="flex" alignItems="center" mb={1}>
            <Typography style={{ fontWeight: "bold" }}>
              {comment.author_name}
            </Typography>
            <Box flexGrow={1} />
            <Typography color="textSecondary" variant="caption">
              {formatDistanceToNow(new Date(comment.created_at))}
            </Typography>
          </Box>
          <Typography variant="body1" color="textPrimary">
            <Linkify
              componentDecorator={(
                decoratedHref: string,
                decoratedText: string,
                key: any
              ) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={decoratedHref}
                  key={key}
                >
                  {decoratedText}
                </a>
              )}
            >
              {comment.content}
            </Linkify>
          </Typography>
          {canDelete() && (
            <Box mt={1}>
              <Typography
                variant="subtitle2"
                color="secondary"
                className={classes.deleteLink}
                onClick={confirmDelete}
              >
                Delete
              </Typography>
            </Box>
          )}
        </Box>
      </div>
      <ConfirmationDialog
        open={confirmDeleteOpen}
        title="Delete comment?"
        description="Are you sure you wish to delete this comment?"
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleDelete}
        onCancel={cancelDelete}
      />
    </>
  );
};

export default Comment;
