import React from "react";
import { Box, Backdrop, Typography, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    color: "#000",
    backgroundColor: "#fff",
  },
});

const LoadingSpinner: React.FC = () => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.root} open={true}>
      <CircularProgress color="primary" />
      <Box ml={4}>
        <Typography variant="h6">Loading, please wait...</Typography>
      </Box>
    </Backdrop>
  );
};

export default LoadingSpinner;
