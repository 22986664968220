import { AuthToken } from "../types/auth";
import jwt_decode from "jwt-decode";
import axios from "axios";
import config from "../config";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

// Helper function to decode JWT string
const decode = (token: string): AuthToken => {
  return jwt_decode(token);
};

// Fetch and decode the user token
export const getDecodedToken = (): AuthToken | null => {
  const token = localStorage.getItem("token");

  if (!token) {
    return null;
  }

  return decode(token);
};

// Call the API and get user data with ID from token
const getUserByID = async (id: number) => {
  try {
    const response = await axios.get(
      `${config.API_URL}/users/${id}`,
      config.getAxiosJwtHeader()
    );

    return response ? response.data : null;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Check if JWT is expired
const isExpired = (decodedToken: AuthToken) => {
  const currentDate = new Date();

  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    return true;
  }

  return false;
};

// Check token expiration and return user if valid
export const getUserFromToken = async () => {
  const decodedToken = getDecodedToken();
  if (!decodedToken) return null;

  if (isExpired(decodedToken)) {
    localStorage.removeItem("token");
    return null;
  }

  try {
    return await getUserByID(decodedToken.id);
  } catch (error) {
    throw new Error(error.message);
  }
};

// Login method that sets user state and returns status
export const login = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${config.API_URL}/users/login`, {
      email,
      password,
    });

    localStorage.setItem("token", response.data.token);
    const user = await getUserFromToken();

    return user ? user : null;
  } catch (error) {
    if (error.response.status === 401) {
      throw new Error("Username or password is incorrect.");
    }
  }
};

// Clear token and reset user state
export const logout = () => {
  localStorage.removeItem("token");
  history.push(config.ROOT_URL);
};
