import React, { useState, useEffect } from "react";
import { makeStyles, Modal, Typography } from "@material-ui/core";
import MediaCard from "../components/MediaCard";
import PageHeader from "../components/PageHeader";
import ModalVideoPlayer from "../components/ModalVideoPlayer";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import config from "../config";

export type MediaItemType = {
  title: string;
  subtitle?: string;
  description?: string;
  filename: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    maxWidth: 1200,
    height: "100%",
  },
  mediaContainer: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  mediaCard: {
    margin: 8,
    transition: "transform 0.3s",
    "&:hover": {
      backgroundColor: "#fbfbfb",
      cursor: "pointer",
      transform: "scale(1.02)",
    },
  },
  hoverOverlay: {
    "&:hover": {
      color: "black",
      opacity: "20%",
    },
  },
  playerModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  modalBackdrop: {
    backgroundColor: "black",
  },
  videoHeader: {
    marginBottom: "2rem",
    textAlign: "center",
  },
  loadingContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const MediaGalleryPage: React.FC = () => {
  const classes = useStyles();
  const [mediaItems, setMediaItems] = useState<null | [MediaItemType]>(null);
  const [activeMediaItem, setActiveMediaItem] = useState<MediaItemType | null>(
    null
  );

  useEffect(() => {
    const fetchMediaItems = async () => {
      try {
        const response = await axios.get(
          `${config.API_URL}/media`,
          config.getAxiosJwtHeader()
        );

        response && response.status === 200
          ? setMediaItems(response.data)
          : console.error(
              `fetchMediaItems failed with response ${response.status}`
            );
      } catch (e) {
        console.error(e);
      }
    };

    fetchMediaItems();
  }, []);

  const playMedia = (mediaItem: MediaItemType) => {
    setActiveMediaItem(mediaItem);
  };

  const handleClose = () => {
    setActiveMediaItem(null);
  };

  return (
    <div className={classes.root}>
      <PageHeader text="Media Gallery" />
      {mediaItems ? (
        <div className={classes.mediaContainer}>
          {mediaItems.length >= 1 ? (
            mediaItems.map((mediaItem: any) => (
              <MediaCard
                key={mediaItem.filename}
                className={classes.mediaCard}
                mediaItem={mediaItem}
                playMedia={playMedia}
              />
            ))
          ) : (
            <Typography>No media available.</Typography>
          )}
        </div>
      ) : (
        <div className={classes.loadingContainer}>
          <CircularProgress color="primary" />
        </div>
      )}
      {activeMediaItem && (
        <Modal
          open={Boolean(activeMediaItem)}
          onClick={handleClose}
          className={classes.playerModal}
          BackdropProps={{
            style: { backgroundColor: "rgba(0, 0, 0, 0.92)" },
          }}
        >
          <>
            <div className={classes.videoHeader}>
              <Typography variant="h5">{activeMediaItem.title}</Typography>
              <Typography variant="subtitle1">
                {activeMediaItem.subtitle}
              </Typography>
            </div>
            <ModalVideoPlayer mediaItem={activeMediaItem} />
          </>
        </Modal>
      )}
    </div>
  );
};

export default MediaGalleryPage;
