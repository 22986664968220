import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

type ConfirmationDialogProps = {
  open: boolean;
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  description,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{title || "Confirm Delete"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description || "Are you sure you wish to delete?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        <Button color="primary" variant="contained" onClick={onCancel}>
          {cancelText || "Cancel"}
        </Button>
        <Button color="secondary" variant="contained" onClick={onConfirm}>
          {confirmText || "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
