import React, { useState, useEffect } from "react";
import clsx from "clsx";
import config from "../../config";
import axios from "axios";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
// @ts-ignore
import { Lightbox } from "react-modal-image";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  Divider,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Reactions from "./Reactions";
import Comment from "./Comment";
import CommentAdd from "./CommentAdd";
import Linkify from "react-linkify";
import { useAppSelector } from "../../redux/hooks";
import { Post, PostComment } from "../../types/posts";

type PostCardProps = {
  className?: string;
  post: Post;
  refreshCallback(): any;
};

const useStyles = makeStyles((theme) => ({
  root: { marginTop: 10, boxShadow: "1px 1px 4px #E9ECEE" },
  date: {
    marginLeft: 6,
  },
  cardHeader: {
    display: "flex",
    padding: 10,
    marginBottom: 20,
  },
  media: {
    height: 500,
    backgroundPosition: "top",
  },
  deleteButton: {
    margin: 6,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  showAllText: {
    marginBottom: 22,
    "&:hover": {
      cursor: "pointer",
    },
  },
  videoEmbed: {
    marginTop: "1rem",
    width: "100%",
    height: 400,
    "& > iframe": {
      width: "100%",
      height: "100%",
    },
  },
}));

const renderComments = (
  comments: PostComment[],
  refreshCallback: () => void
) => {
  return comments.map((comment: PostComment) => (
    <Comment
      comment={comment}
      key={comment.id}
      refreshCallback={refreshCallback}
    />
  ));
};

const PostCard: React.FC<PostCardProps> = ({
  className,
  post,
  refreshCallback,
  ...rest
}) => {
  const classes = useStyles();
  const auth = useAppSelector((state) => state.auth);

  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visibleComments, setVisibleComments] = useState<PostComment[] | null>(
    null
  );

  const imageUrl = (filename: string) => {
    return config.CDN_IMAGE_URL + filename;
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleOpen = () => {
    setConfirmOpen(true);
  };

  const handleDelete = () => {
    setIsLoading(true);
    axios
      .delete(`${config.API_URL}/posts/${post.id}`, config.getAxiosJwtHeader())
      .then((response) => {
        response.status === 200 && console.log("Successfully deleted post.");
        setIsLoading(false);
        handleClose();
        refreshCallback();
      })
      .catch((e) => {
        console.error(e.response);
        setIsLoading(false);
        handleClose();
        refreshCallback();
      });
  };

  const showAllComments = () => {
    setVisibleComments(null);
  };

  useEffect(() => {
    if (post.comments && post.comments.length > 5) {
      setVisibleComments(
        post.comments.slice(post.comments.length - 5, post.comments.length)
      );
    } else {
      setVisibleComments(null);
    }
    // eslint-disable-next-line
  }, [post.comments]);

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Avatar
              alt="Person"
              src={post.avatar ? config.CDN_AVATAR_URL + post.avatar : ""}
            />
          }
          disableTypography
          subheader={
            <Box display="flex" alignItems="center" fontSize={14}>
              <AccessTimeIcon fontSize="inherit" />
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.date}
              >
                {formatDistanceToNow(new Date(post.created_at))}
              </Typography>
            </Box>
          }
          title={
            <Typography style={{ fontWeight: "bold" }}>
              {post.author_name}
            </Typography>
          }
          action={
            auth.user?.role === "Administrator" || auth.user?.role === "RHB" ? (
              <Tooltip title="Delete">
                <IconButton
                  className={classes.deleteButton}
                  onClick={handleOpen}
                >
                  <DeleteOutlinedIcon style={{ color: "#161B25" }} />
                </IconButton>
              </Tooltip>
            ) : null
          }
        />
        <Box px={3} pb={2}>
          <Typography variant="body1" color="textPrimary">
            <Linkify
              componentDecorator={(
                decoratedHref: string,
                decoratedText: string,
                key: any
              ) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={decoratedHref}
                  key={key}
                >
                  {decoratedText}
                </a>
              )}
            >
              {post.content}
            </Linkify>
          </Typography>
          {post.img_filename && (
            <Box mt={2}>
              <CardActionArea
                onClick={() =>
                  setSelectedImage(imageUrl(post.img_filename as string))
                }
              >
                <CardMedia
                  className={classes.media}
                  image={imageUrl(post.img_filename)}
                />
              </CardActionArea>
            </Box>
          )}
          {post.embed_code && (
            <div
              className={classes.videoEmbed}
              dangerouslySetInnerHTML={{ __html: post.embed_code }}
            />
          )}
          <Box mt={2}>
            <Reactions post={post} />
          </Box>
          {post.comments ? (
            <Box mt={2}>
              <Box my={2}>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                  style={{ marginBottom: 6 }}
                >{`${post.comments.length} comments`}</Typography>
                <Divider />
              </Box>
              {visibleComments && (
                <Typography
                  onClick={showAllComments}
                  color="textSecondary"
                  className={classes.showAllText}
                  variant="subtitle2"
                >
                  Show more comments
                </Typography>
              )}
              {renderComments(
                visibleComments ? visibleComments : post.comments,
                refreshCallback
              )}
              <Box my={2}>
                <Divider />
              </Box>
            </Box>
          ) : (
            <Divider
              style={{
                marginTop: 20,
                marginBottom: 15,
                backgroundColor: "#E1E2E2",
              }}
            />
          )}
          <CommentAdd post={post} refreshCallback={refreshCallback} />
        </Box>
      </Card>
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Delete post?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this post?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            margin: "0.5rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="secondary"
            variant="contained"
            disabled={isLoading}
          >
            {!isLoading ? "Delete" : "Deleting..."}
          </Button>
        </DialogActions>
      </Dialog>
      {selectedImage && (
        <Lightbox
          hideDownload
          large={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </>
  );
};

export default PostCard;
