import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import invitesReducer from "./slices/invites";

export const store = configureStore({
  reducer: { auth: authReducer, invites: invitesReducer },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
