import axios from "axios";
import config from "../config";

export const createAccount = async (userData: any) => {
  try {
    const response = await axios.post(
      `${config.API_URL}/users`,
      userData,
      config.getAxiosJwtHeader()
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
