import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  TextField,
  IconButton,
  Button,
  Modal,
  LinearProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
  formRoot: {
    padding: "2rem",
    width: "90%",
    maxWidth: 600,
    minWidth: 300,
    margin: "0px auto",
  },
  formHeader: {
    marginBottom: "1.5rem",
    textAlign: "center",
  },
  inputField: {
    width: "100%",
    marginBottom: "2rem",
  },
  uploadHeader: {
    margin: "1rem auto",
    textAlign: "center",
  },
  fileDropContainer: {
    width: "100%",
    height: 300,
    border: "1px dotted gray",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: "auto",
    cursor: "pointer",
    borderRadius: 16,
    color: "gray",
    "&:hover": {
      boxShadow: "2px 2px 20px rgba(0, 0, 0, 0.19)",
    },
  },
  fileDropAcceptedContainer: {
    width: "100%",
    height: 300,
    border: "1px dotted gray",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: "auto",
    borderRadius: 16,
  },
  fileAcceptedInfo: {
    position: "relative",
    top: 24,
  },
  deleteIconButton: {
    margin: "1rem auto",
    display: "block",
    color: theme.palette.secondary.main,
  },
  fileDropAcceptedName: {
    color: "green",
    marginBottom: "2rem",
  },
  fileErrorText: {
    color: "red",
    textAlign: "center",
    marginTop: "1rem",
  },
  submitButton: {
    marginTop: "3rem",
  },
  modalRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  modalProgressIndicator: {
    backgroundColor: "#fefefe",
    width: "50%",
    maxWidth: 600,
    textAlign: "center",
    color: "#333",
    padding: "2rem",
    borderRadius: 16,
  },
  formFeedback: {
    marginTop: "1rem",
  },
}));

const MediaUploadForm: React.FC = () => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [fileMissingError, setFileMissingError] = useState(false);
  const [progress, setProgress] = useState(0);

  const { getRootProps, getInputProps, isDragActive } = useDropzone();

  return (
    <Paper className={classes.formRoot}>
      <Formik
        initialValues={{
          title: "",
          subtitle: "",
          description: "",
          file: null,
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .required("The content title is required.")
            .min(5, "Minimum of 5 characters allowed.")
            .max(80, "Maximum of 80 characters allowed."),
          subtitle: Yup.string()
            .min(5, "Minimum of 5 characters allowed.")
            .max(80, "Maximum of 80 characters allowed."),
          description: Yup.string().max(
            80,
            "Maximun of 80 characters allowed."
          ),
          file: Yup.mixed().required("A file attachment is required."),
        })}
        onSubmit={async (values: any, actions) => {
          const formData = new FormData();

          for (let key in values) {
            formData.append(key, (values as any)[key]);
          }

          try {
            const result = await axios.post(
              `${config.API_URL}/media`,
              formData,
              {
                headers: {
                  ...config.getAxiosJwtHeader().headers,
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                  setProgress(
                    Math.round(
                      100 * (progressEvent.loaded / progressEvent.total)
                    )
                  );
                },
              }
            );

            if (result.status === 201) {
              setSuccess(true);
              actions.setFieldValue("file", null);
              actions.setSubmitting(false);
              setProgress(0);
            }
          } catch (e) {
            console.error(e);
            setError(true);
            actions.setFieldValue("file", null);
            actions.setSubmitting(false);
            setProgress(0);
          }
        }}
      >
        {(formik) => (
          <>
            <form onSubmit={formik.handleSubmit}>
              <Typography variant="h6" className={classes.formHeader}>
                Media Information
              </Typography>
              <TextField
                className={classes.inputField}
                {...formik.getFieldProps("title")}
                id="title"
                label="Title"
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                variant="outlined"
              />
              <TextField
                className={classes.inputField}
                {...formik.getFieldProps("subtitle")}
                id="subtitle"
                label="Author/Subtitle"
                error={
                  formik.touched.subtitle && Boolean(formik.errors.subtitle)
                }
                helperText={formik.touched.subtitle && formik.errors.subtitle}
                variant="outlined"
              />
              <TextField
                className={classes.inputField}
                {...formik.getFieldProps("description")}
                id="description"
                label="Description"
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                variant="outlined"
              />
              <Typography variant="h6" className={classes.uploadHeader}>
                Upload File
              </Typography>
              {formik.values.file ? (
                <div className={classes.fileDropAcceptedContainer}>
                  <div className={classes.fileAcceptedInfo}>
                    <Typography style={{ color: "green" }}>
                      {(formik.values.file as any).name}
                    </Typography>
                    <IconButton
                      className={classes.deleteIconButton}
                      onClick={() => {
                        formik.setFieldValue("file", null);
                      }}
                    >
                      <DeleteIcon fontSize="large" />
                    </IconButton>
                  </div>
                </div>
              ) : (
                <div {...getRootProps()} className={classes.fileDropContainer}>
                  <input
                    {...getInputProps()}
                    onChange={(e: any) => {
                      formik.setErrors({ file: undefined });
                      const supportedTypes = ["video/mp4", "video/webm"];

                      if (
                        e.target.files[0] &&
                        !supportedTypes.includes(e.target.files[0].type)
                      ) {
                        alert(
                          "Invalid file type! Only MP4 and WebM videos are supported."
                        );
                      } else {
                        formik.setFieldValue("file", e.target.files[0]);
                      }
                    }}
                  />
                  {isDragActive ? (
                    <Typography>Drop here...</Typography>
                  ) : (
                    <Typography>Drop file here or click to upload.</Typography>
                  )}
                </div>
              )}
              {fileMissingError && (
                <Typography className={classes.fileErrorText}>
                  {formik.errors.file}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={formik.isSubmitting}
                fullWidth
                className={classes.submitButton}
                onClick={() => {
                  if (!formik.values.file) {
                    setFileMissingError(true);
                  }
                }}
              >
                {formik.isSubmitting ? "Submitting..." : "Upload"}
              </Button>
            </form>
            {success ? (
              <div className={classes.formFeedback}>
                <Alert severity="success">
                  Media item uploaded successfully.
                </Alert>
              </div>
            ) : error ? (
              <div className={classes.formFeedback}>
                <Alert severity="error">
                  There was a problem, please try again later.
                </Alert>
              </div>
            ) : null}

            <Modal
              className={classes.modalRoot}
              open={formik.isSubmitting}
              BackdropProps={{
                style: { backgroundColor: "rgba(0, 0, 0, 0.92)" },
              }}
            >
              <div className={classes.modalProgressIndicator}>
                <Typography style={{ marginBottom: "1rem" }}>
                  Uploading media...
                </Typography>
                {formik.values.file && (
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: "2rem" }}
                  >
                    {`${progress}% uploaded of ${Math.round(
                      formik.values.file.size / 1000
                    )} MB`}
                  </Typography>
                )}
                <LinearProgress variant="determinate" value={progress} />
              </div>
            </Modal>
          </>
        )}
      </Formik>
    </Paper>
  );
};

export default MediaUploadForm;
