import { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";

const useUserCount = () => {
  const [count, setCount] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchUserCount = async () => {
      try {
        const response = await axios.get(
          `${config.API_URL}/users?countOnly`,
          config.getAxiosJwtHeader()
        );

        setCount(response.data.count);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserCount();
  }, []);

  return { loading, count };
};

export default useUserCount;
