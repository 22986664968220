import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { format, parseISO } from "date-fns";
import OptionsIcon from "@material-ui/icons/MoreVert";
import { deleteInvite, resendInvite } from "../redux/slices/invites";
import { Invite } from "../types/users";
import ConfirmationDialog from "./ConfirmationDialog";
import AlertToast from "./AlertToast";

const useStyles = makeStyles((theme) => ({
  table: { tableLayout: "fixed" },
  actionCell: {
    width: "3rem",
    position: "relative",
    "& > *": {
      fontSize: "1.5rem",
      color: "#555",
      position: "absolute",
      top: 14,
      left: 18,
    },
    "&:hover": {
      cursor: "pointer",
      "& > *": {
        color: "black",
      },
    },
  },
  headerText: {
    fontWeight: "bold",
  },
  deleteOption: {
    color: theme.palette.secondary.main,
  },
  resendOption: {
    color: theme.palette.primary.main,
  },
}));

const InvitesTable = () => {
  const classes = useStyles();
  const invites = useAppSelector((state) => state.invites);
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmResendOpen, setConfirmResendOpen] = useState(false);

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);

  const [deleteError, setDeleteError] = useState(false);
  const [resendError, setResendError] = useState(false);

  const [isDeletingId, setIsDeletingId] = useState<null | number>(null);

  // Trigger alerts based on dispatch result
  useEffect(() => {
    invites.status !== "deleting" && setIsDeletingId(null);

    invites.status === "deleteSuccessful" && setDeleteSuccess(true);
    invites.status === "deleteFailed" && setDeleteError(true);
    invites.status === "resendSuccessful" && setResendSuccess(true);
    invites.status === "resendFailed" && setResendError(true);
  }, [invites.status]);

  const handleOpen = (event: React.MouseEvent<HTMLTableCellElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (id: number) => {
    setIsDeletingId(id);
    setConfirmDeleteOpen(false);
    dispatch(deleteInvite(id));
  };

  const confirmDelete = () => {
    setAnchorEl(null);
    setConfirmDeleteOpen(true);
  };

  const handleResend = (id: number) => {
    dispatch(resendInvite(id));
    setConfirmResendOpen(false);
  };

  const confirmResend = () => {
    setAnchorEl(null);
    setConfirmResendOpen(true);
  };

  const handleAlertClose = () => {
    deleteSuccess && setDeleteSuccess(false);
    deleteError && setDeleteError(false);
    resendSuccess && setResendSuccess(false);
    resendError && setResendError(false);
  };

  const handleConfirmationClose = () => {
    confirmDeleteOpen && setConfirmDeleteOpen(false);
    confirmResendOpen && setConfirmResendOpen(false);
  };

  return (
    <>
      {invites.status === "failed" ? (
        <Typography style={{ textAlign: "center", marginTop: "1rem" }}>
          No invites available.
        </Typography>
      ) : invites.ids.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.actionCell} />
                  <TableCell className={classes.headerText}>
                    First name
                  </TableCell>
                  <TableCell className={classes.headerText}>
                    Last name
                  </TableCell>
                  <TableCell className={classes.headerText}>Email</TableCell>
                  <TableCell className={classes.headerText}>
                    Invited at
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(Object.values(invites.entities) as Invite[]).map((invite) =>
                  isDeletingId && isDeletingId === invite.id ? (
                    <TableRow key={invite.id}>
                      <TableCell>
                        <Skeleton variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rect" />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <React.Fragment key={invite.id}>
                      <TableRow>
                        <TableCell
                          className={classes.actionCell}
                          onClick={handleOpen}
                        >
                          <Tooltip title="Invite actions">
                            <OptionsIcon />
                          </Tooltip>
                        </TableCell>
                        <TableCell>{invite.first_name || "N/A"}</TableCell>
                        <TableCell>{invite.last_name || "N/A"}</TableCell>
                        <TableCell>{invite.email_address}</TableCell>
                        <TableCell>
                          {invite.created_at
                            ? format(
                                parseISO(invite.created_at),
                                "MMMM do', 'yyyy' at 'h:mm a"
                              )
                            : ""}
                        </TableCell>
                      </TableRow>
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={!!anchorEl}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={confirmDelete}
                          className={classes.deleteOption}
                        >
                          Delete
                        </MenuItem>
                        <MenuItem
                          onClick={confirmResend}
                          className={classes.resendOption}
                        >
                          Resend
                        </MenuItem>
                      </Menu>
                      <ConfirmationDialog
                        open={confirmDeleteOpen}
                        title={"Delete invite?"}
                        description="Are you sure you wish to delete this invite?"
                        onConfirm={() => handleDelete(invite.id)}
                        onCancel={handleConfirmationClose}
                      />
                      <ConfirmationDialog
                        open={confirmResendOpen}
                        title={"Resend invitation?"}
                        description="Are you sure you wish to resend the invite email?"
                        onConfirm={() => handleResend(invite.id)}
                        onCancel={handleConfirmationClose}
                      />
                    </React.Fragment>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography style={{ textAlign: "center", marginTop: "1rem" }}>
          No pending invites.
        </Typography>
      )}
      <AlertToast
        open={deleteSuccess}
        onClose={handleAlertClose}
        severity="success"
        text="Invite deleted!"
      />
      <AlertToast
        open={deleteError}
        onClose={handleAlertClose}
        severity="error"
        text="Error deleting invite."
      />
      <AlertToast
        open={resendSuccess}
        onClose={handleAlertClose}
        severity="success"
        text="Invite resent!"
      />
      <AlertToast
        open={resendError}
        onClose={handleAlertClose}
        severity="error"
        text="Error resending invite."
      />
    </>
  );
};

export default InvitesTable;
