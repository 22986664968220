import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { Invite, InviteData } from "../../types/users";
import {
  getInvites,
  createInvite,
  deleteInviteById,
  resendInviteById,
} from "../../services/invites";

type Status =
  | "idle"
  | "loading"
  | "deleting"
  | "deleteSuccessful"
  | "deleteFailed"
  | "sending"
  | "sendSuccessful"
  | "sendFailed"
  | "resending"
  | "resendSuccessful"
  | "resendFailed"
  | "successful"
  | "failed";

type Error = null | string;

const invitesAdapter = createEntityAdapter<Invite>();
const initialState = invitesAdapter.getInitialState({
  status: "idle" as Status,
  error: null as Error,
});

export const fetchInvites = createAsyncThunk(
  "invites/getInvites",
  async (_, { rejectWithValue }) => {
    try {
      return await getInvites();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const sendInvite = createAsyncThunk(
  "invites/sendInvite",
  async (inviteData: InviteData, { rejectWithValue }) => {
    try {
      return await createInvite(inviteData);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const resendInvite = createAsyncThunk(
  "invites/resendInvite",
  async (id: number, { rejectWithValue }) => {
    try {
      return await resendInviteById(id);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteInvite = createAsyncThunk(
  "invites/deleteInvite",
  async (id: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const result = await deleteInviteById(id);

      if (result) {
        return fulfillWithValue(id as any);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const invitesSlice = createSlice({
  name: "invites",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvites.pending, (state: any) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchInvites.fulfilled, (state: any, action) => {
        invitesAdapter.setAll(state, action.payload);
        state.status = "successful";
      })
      .addCase(fetchInvites.rejected, (state: any, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteInvite.pending, (state: any) => {
        state.status = "deleting";
        state.error = null;
      })
      .addCase(deleteInvite.fulfilled, (state: any, action) => {
        invitesAdapter.removeOne(state, action.payload);
        state.status = "deleteSuccessful";
      })
      .addCase(deleteInvite.rejected, (state: any, action) => {
        state.status = "deleteFailed";
        state.error = action.payload;
      })
      .addCase(sendInvite.pending, (state: any) => {
        state.status = "sending";
        state.error = null;
      })
      .addCase(sendInvite.fulfilled, (state: any, action) => {
        state.status = "sendSuccessful";
        invitesAdapter.addOne(state, action.payload);
      })
      .addCase(sendInvite.rejected, (state: any, action) => {
        state.status = "sendFailed";
        state.error = action.payload;
      })
      .addCase(resendInvite.pending, (state: any) => {
        state.status = "resending";
        state.error = null;
      })
      .addCase(resendInvite.fulfilled, (state: any) => {
        state.status = "resendSuccessful";
      })
      .addCase(resendInvite.rejected, (state: any) => {
        state.status = "resendFailed";
      });
  },
});

export default invitesSlice.reducer;
