import React from "react";
import { makeStyles } from "@material-ui/core";
import PageHeader from "../components/PageHeader";
import MediaUploadForm from "../components/Forms/MediaUploadForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    maxWidth: 1200,
    height: "100%",
  },
}));

const MediaUploadPage: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <PageHeader text="Upload Media" />
      <MediaUploadForm />
    </div>
  );
};

export default MediaUploadPage;
