import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Button,
  TextField,
  FormGroup,
} from "@material-ui/core";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { login } from "../../redux/slices/auth";

type LoginData = {
  email: string;
  password: string;
};

const useStyles = makeStyles((theme) => ({
  formHeaderText: {
    marginBottom: 30,
    textAlign: "center",
  },
  loginContainer: {
    padding: 20,
    minWidth: 400,
  },
  formTextField: {
    marginBottom: 20,
  },
  submitButton: {
    margin: "5px 0",
  },
  errorText: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    margin: "18px 0",
  },
  otherActionsContainer: {
    marginTop: 12,
  },
  otherActionsFirst: {
    marginBottom: 4,
  },
}));

const LoginForm: React.FC = () => {
  const classes = useStyles();
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [loginData, setLoginData] = useState<LoginData>({
    email: "",
    password: "",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    await dispatch(
      login({ email: loginData.email, password: loginData.password })
    );
  };

  const handleInputChange = (e: React.FormEvent) => {
    const target = e.target as HTMLTextAreaElement;
    setLoginData({ ...loginData, [target.name]: target.value });
  };

  return (
    <Paper className={classes.loginContainer}>
      <Typography variant="h4" className={classes.formHeaderText}>
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <TextField
            id="email"
            name="email"
            label="Email"
            variant="outlined"
            helperText="Enter your email address"
            className={classes.formTextField}
            onChange={handleInputChange}
            autoFocus
            required
          />
          <TextField
            id="password"
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            helperText="Enter your password"
            className={classes.formTextField}
            onChange={handleInputChange}
            required
          />
        </FormGroup>
        {auth.status === "loggingIn" ? (
          <Button className={classes.submitButton} size="large" disabled>
            Logging in...
          </Button>
        ) : (
          <Button
            variant="contained"
            className={classes.submitButton}
            size="large"
            type="submit"
            color="primary"
          >
            Submit
          </Button>
        )}
        {auth.error && (
          <Typography className={classes.errorText}>{auth.error}</Typography>
        )}
      </form>
      <div className={classes.otherActionsContainer}>
        <Typography variant="body2" className={classes.otherActionsFirst}>
          Need an account? <Link to="/apply">Apply for admission.</Link>
        </Typography>
        <Typography variant="body2">
          Forgot your password? <Link to="/reset">Reset your password.</Link>
        </Typography>
      </div>
    </Paper>
  );
};

export default LoginForm;
