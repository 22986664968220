import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

// Page imports
import DashboardPage from "../pages/DashboardPage";
import MediaGalleryPage from "../pages/MediaGalleryPage";
import ApplicationsPage from "../pages/ApplicationsPage";
import ProfilePage from "../pages/ProfilePage";
import AboutPage from "../pages/AboutPage";
import MediaUploadPage from "../pages/MediaUploadPage";
import InvitePage from "../pages/InvitePage";

const DashboardRouter: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/">
        <DashboardPage />
      </Route>
      <Route path="/applications">
        <ApplicationsPage />
      </Route>
      <Route path="/profile">
        <ProfilePage />
      </Route>
      <Route path="/about">
        <AboutPage />
      </Route>
      <Route path="/media">
        <MediaGalleryPage />
      </Route>
      <Route path="/media-upload">
        <MediaUploadPage />
      </Route>
      <Route path="/invites">
        <InvitePage />
      </Route>
      <Route>
        <Redirect to="/not-found" />
      </Route>
    </Switch>
  );
};

export default DashboardRouter;
