import React from "react";
import {
  makeStyles,
  useTheme,
  Typography,
  Link as UILink,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.secondary.dark,
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const AppLayout: React.FC = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Typography
        variant="body2"
        style={{ color: theme.palette.primary.light }}
        align="center"
      >
        {"© 2021 "}
        <UILink
          color="inherit"
          href="https://www.revival.com"
          target="_blank"
          rel="noreferrer"
        >
          Revival Ministries International
        </UILink>
      </Typography>
    </footer>
  );
};

export default AppLayout;
