import React, { useState } from "react";
import {
  Menu,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Avatar,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import config from "../config";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { logout as logoutAction } from "../redux/slices/auth";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(1),
    color: theme.palette.primary.light,
    "&:hover": {
      background: "rgba(255, 255, 255, 0.2)",
      cursor: "pointer",
      color: "white",
    },
  },
  userNameListItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "center",
  },
  userNameDivider: { marginTop: 10, marginBottom: 10 },
}));

const userNavList = (handleCloseCallback: any) => {
  const userNavListItems = [{ name: "Your Profile", to: "/profile" }];

  return userNavListItems.map((item, index) => {
    return (
      <ListItem
        button
        key={index}
        component={NavLink}
        to={item.to}
        activeClassName="Mui-selected"
        onClick={handleCloseCallback}
        exact
      >
        <ListItemText primary={item.name} />
      </ListItem>
    );
  });
};

const ProfileMenuIcon: React.FC = () => {
  const classes = useStyles();
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const userFullName = auth.user
    ? `${auth.user.first_name} ${auth.user.last_name}`
    : null;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    dispatch(logoutAction());
  };

  return (
    <>
      <IconButton className={classes.iconButton} onClick={handleClick}>
        <Avatar
          alt="User"
          src={
            auth.user?.avatar ? config.CDN_AVATAR_URL + auth.user?.avatar : ""
          }
          style={{
            width: 42,
            height: 42,
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <List component="nav" style={{ padding: 10 }}>
          {auth && (
            <>
              <ListItem className={classes.userNameListItem}>
                <Typography style={{ marginBottom: 4, fontWeight: "bold" }}>
                  {userFullName}
                </Typography>
                {auth.user?.role !== "RHB" && (
                  <Typography variant="subtitle2">{auth.user?.role}</Typography>
                )}
              </ListItem>
              <Divider className={classes.userNameDivider} />
            </>
          )}
          {userNavList(handleClose)}
          <ListItem button onClick={logout}>
            <ListItemText>Log Out</ListItemText>
          </ListItem>
        </List>
      </Menu>
    </>
  );
};

export default ProfileMenuIcon;
