const config = {
  ROOT_URL:
    process.env.NODE_ENV === "production"
      ? "https://rodneyhowardbrowne.com"
      : "localhost:3333",
  CDN_IMAGE_URL:
    process.env.NODE_ENV === "production"
      ? "https://cdn.rodneyhowardbrowne.com/images/"
      : "https://storage.googleapis.com/rhb-connect-dev/images/",
  CDN_VIDEO_URL:
    process.env.NODE_ENV === "production"
      ? "https://cdn.rodneyhowardbrowne.com/videos/"
      : "https://storage.googleapis.com/rhb-connect-dev/videos/",
  CDN_AVATAR_URL:
    process.env.NODE_ENV === "production"
      ? "https://cdn.rodneyhowardbrowne.com/avatars/"
      : "https://storage.googleapis.com/rhb-connect-dev/avatars/",
  API_URL:
    process.env.NODE_ENV === "production" ||
    process.env.REACT_APP_ENV === "production"
      ? "https://api.rodneyhowardbrowne.com"
      : "http://localhost:3000",
  getAxiosJwtHeader: () => {
    let token = localStorage.getItem("token");
    return { headers: { Authorization: `Bearer ${token}` } };
  },
};

export default config;
