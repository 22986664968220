import React, { useState, useEffect } from "react";
import { makeStyles, Dialog, DialogContent, Button } from "@material-ui/core";
import InviteUserForm from "./InviteUserForm";
import CloseIcon from "@material-ui/icons/Close";
import AlertToast from "../../AlertToast";
import { useAppSelector } from "../../../redux/hooks";

const useStyles = makeStyles((theme) => ({
  dialog: { position: "relative" },
  dialogContent: { padding: "2rem" },
  closeButton: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    fontSize: "2rem",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
    transition: "all 0.4s",
  },
}));

const InviteDialog = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const invites = useAppSelector((state) => state.invites);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAlertClose = () => {
    submitSuccess && setSubmitSuccess(false);
    submitError && setSubmitError(false);
  };

  useEffect(() => {
    if (invites.status === "sendSuccessful") {
      setSubmitSuccess(true);
    }

    if (invites.status === "sendFailed") {
      setSubmitError(true);
    }
  }, [invites.status]);

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleOpen}>
        Send Invite
      </Button>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <CloseIcon onClick={handleClose} className={classes.closeButton} />
        <DialogContent className={classes.dialogContent}>
          <InviteUserForm handleClose={handleClose} />
        </DialogContent>
      </Dialog>
      <AlertToast
        open={submitSuccess}
        onClose={handleAlertClose}
        severity="success"
        text="Invite sent!"
      />
      <AlertToast
        open={submitError}
        onClose={handleAlertClose}
        severity="error"
        text="Error sending invite."
      />
    </>
  );
};

export default InviteDialog;
