import React, { useEffect, useState } from "react";
import type { FC, ChangeEvent } from "react";
import clsx from "clsx";
import axios from "axios";
import config from "../../config";
import {
  Avatar,
  // Divider,
  IconButton,
  Input,
  Paper,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
// import AddPhotoIcon from "@material-ui/icons/AddPhotoAlternate";
import { useAppSelector } from "../../redux/hooks";
import useKeyPress from "../../hooks/useKeyPress";
import { Post, PostComment } from "../../types/posts";

type CommentAddProps = {
  className?: string;
  refreshCallback(): void;
  post: Post;
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
  },
  inputContainer: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  },
  divider: {
    height: 24,
    width: 1,
  },
  fileInput: {
    display: "none",
  },
}));

const CommentAdd: FC<CommentAddProps> = ({
  className,
  post,
  refreshCallback,
  ...rest
}) => {
  const classes = useStyles();
  const auth = useAppSelector((state) => state.auth);
  const enterKey = useKeyPress("Enter");
  // const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };

  // const handleAttach = (): void => {
  //   (fileInputRef.current as any).click();
  // };

  const handleSubmit = () => {
    setIsSubmitting(true);
    axios
      .post(
        `${config.API_URL}/posts/comments`,
        {
          postId: post.id,
          content: value,
        },
        config.getAxiosJwtHeader()
      )
      .then((response) => {
        setValue("");
        setIsSubmitting(false);
        refreshCallback();
      })
      .catch((e) => {
        setIsSubmitting(false);
        console.error(e.response);
      });
  };

  useEffect(() => {
    enterKey && value && !isSubmitting && handleSubmit();
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Avatar
        alt="Person"
        src={auth.user?.avatar ? config.CDN_AVATAR_URL + auth.user?.avatar : ""}
      />
      <Paper className={classes.inputContainer} variant="outlined">
        <Input
          disableUnderline
          fullWidth
          onChange={handleChange}
          placeholder=" Leave a comment"
          value={value}
        />
      </Paper>
      <Tooltip title="Send">
        <span>
          <IconButton
            color={value.length > 0 ? "primary" : "default"}
            disabled={!value || isSubmitting}
            onClick={handleSubmit}
          >
            <SendIcon />
          </IconButton>
        </span>
      </Tooltip>
      {/* <Divider className={classes.divider} />
      <Tooltip title="Attach image">
        <IconButton edge="end" onClick={handleAttach}>
          <AddPhotoIcon />
        </IconButton>
      </Tooltip>
      <input
        id="add-photo"
        className={classes.fileInput}
        ref={fileInputRef}
        type="file"
      /> */}
    </div>
  );
};

export default CommentAdd;
