import React, { useState, useEffect, useRef } from "react";
import type { FC, ChangeEvent } from "react";
import clsx from "clsx";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Input,
  Button,
  Paper,
  Tooltip,
  Snackbar,
  makeStyles,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import SendIcon from "@material-ui/icons/Send";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import CancelIcon from "@material-ui/icons/Cancel";
import CodeIcon from "@material-ui/icons/Code";
import axios from "axios";
import config from "../config";
import useKeyPress from "../hooks/useKeyPress";
import { useAppSelector } from "../redux/hooks";
import { fetchEmbedData } from "../services/oembed";
import useUserCount from "../hooks/useUserCount";

type PostAddProps = {
  className?: string;
  addCallback(): any;
};

const useStyles = makeStyles((theme) => ({
  root: { boxShadow: "none", border: "1px solid #E0E0E0" },
  postHeader: {
    fontSize: "1.5rem",
    marginLeft: 4,
  },
  postSubheader: {
    marginLeft: 4,
    marginTop: "0.25rem",
    marginBottom: "1.5rem",
  },
  inputContainer: {
    flexGrow: 1,
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    marginRight: "0.5rem",
  },
  divider: {
    height: 24,
    width: 1,
  },
  embedInputButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  dialogContent: {
    minWidth: 320,
  },
  fileInputButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  fileInput: {
    display: "none",
  },
  previewImage: {
    margin: "2rem 0 1rem 0",
    maxWidth: "80%",
    height: "auto",
    maxHeight: "300px",
  },
  embedRoot: {
    marginTop: "1rem",
    height: 400,
    width: "100%",
    "& > iframe": {
      height: "100%",
      width: "100%",
    },
  },
}));

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const PostAdd: FC<PostAddProps> = ({ className, addCallback, ...rest }) => {
  const classes = useStyles();
  const auth = useAppSelector((state) => state.auth);

  const userCount = useUserCount();
  const enterKey = useKeyPress("Enter");

  const [value, setValue] = useState("");
  const [embedValue, setEmbedValue] = useState("");
  const [embedDialogOpen, setEmbedDialogOpen] = useState(false);
  const [embedHtml, setEmbedHtml] = useState<null | string>(null);
  const [embedLoading, setEmbedLoading] = useState(false);

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreviewData, setImagePreviewData] = useState<string | null>(null);
  const [imagePreviewLoaded, setImagePreviewLoaded] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const imagePreviewRef = useRef();

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setValue(event.target.value);
  };

  const handleDialogChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmbedValue(event.target.value);
  };

  const handleEmbedSubmit = async () => {
    if (!embedValue) return;

    setEmbedLoading(true);

    try {
      const response = await fetchEmbedData(embedValue);

      setEmbedLoading(false);
      setEmbedDialogOpen(false);

      if (response) {
        setEmbedHtml(response.html);
        return;
      }

      alert("There was a problem fetching embed data. Is this link valid?");
      return;
    } catch (error) {
      setEmbedLoading(false);
      setEmbedDialogOpen(false);
      console.error(error);
      return;
    }
  };

  const handleEmbedRemove = () => {
    setEmbedHtml(null);
    setEmbedValue("");
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    success && setSuccess(false);
    error && setError(false);
  };

  const handleDialogClose = () => {
    setEmbedDialogOpen(false);
  };

  const handleImageInput = (event: ChangeEvent<HTMLInputElement>): void => {
    if ((event.target.files as any)[0].type.split("/")[0] !== "image") {
      console.error("Not an image");
      alert(
        "File type not allowed! Only images are allowed for post image uploads."
      );
      return;
    }

    event.target.files && setImageFile(event.target.files[0]);
  };

  const handleImageRemove = () => {
    setImagePreviewLoaded(false);
    setImagePreviewData(null);
    setImageFile(null);

    (imagePreviewRef.current as any).src = "";
  };

  useEffect(() => {
    !imagePreviewLoaded && setImagePreviewLoaded(false);

    if (imageFile && imagePreviewRef.current) {
      const reader = new FileReader();

      reader.addEventListener("load", function () {
        (imagePreviewRef.current as any).src = reader.result;
        setImagePreviewLoaded(true);
      });

      reader.readAsDataURL(imageFile);
    }
    // eslint-disable-next-line
  }, [imageFile]);

  useEffect(() => {
    (success || error) &&
      setTimeout(() => {
        success && setSuccess(false);
        error && setError(false);
      }, 3000);
  }, [success, error]);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append(
      "json",
      JSON.stringify({
        content: value,
        embed: embedHtml || null,
        isSticky: false,
      })
    );
    formData.append("image", imageFile as any);

    setSubmitting(true);
    setValue("");

    axios
      .post(`${config.API_URL}/posts`, formData, {
        headers: {
          ...config.getAxiosJwtHeader().headers,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 201) {
          handleSuccess();
        }
      })
      .catch((e) => {
        console.error(e.response);
        setError(true);
        setSubmitting(false);
      });
  };

  const handleSuccess = () => {
    setSuccess(true);
    setSubmitting(false);
    setImageFile(null);
    setImagePreviewData(null);
    setImagePreviewLoaded(false);
    setEmbedValue("");
    setEmbedHtml(null);
    addCallback();
  };

  useEffect(() => {
    enterKey && value && handleSubmit();
    // eslint-disable-next-line
  }, [enterKey]);

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          <Typography variant="h1" className={classes.postHeader}>
            {auth.user &&
              `Welcome ${
                auth.user.role === "RHB" ? "Pastor" : auth.user.first_name
              }!`}
          </Typography>
          {userCount.count && (
            <Typography
              variant="subtitle2"
              className={classes.postSubheader}
            >{`There are currently ${userCount.count} registered users`}</Typography>
          )}
          <Box display="flex" alignItems="center">
            <Paper className={classes.inputContainer} variant="outlined">
              <Input
                disableUnderline
                fullWidth
                onChange={handleChange}
                placeholder={"Speak to the people..."}
                value={value}
              />
            </Paper>
            <Box display="flex" alignItems="center" justifyContent="center">
              {!embedHtml && !imageFile ? (
                <Tooltip title="Embed content">
                  <IconButton
                    color={!submitting ? "primary" : "default"}
                    onClick={() => setEmbedDialogOpen(true)}
                  >
                    <CodeIcon className={classes.embedInputButton} />
                  </IconButton>
                </Tooltip>
              ) : !imageFile ? (
                <Tooltip title="Remove embed">
                  <div>
                    <IconButton
                      color={!submitting ? "secondary" : "default"}
                      disabled={submitting}
                      onClick={handleEmbedRemove}
                    >
                      <CancelIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              ) : null}
              {!imageFile && !embedHtml ? (
                <Tooltip title="Add image">
                  <div>
                    <input
                      type="file"
                      name="postImage"
                      id="postImage"
                      disabled={submitting}
                      className={classes.fileInput}
                      onChange={handleImageInput}
                    />
                    <label htmlFor="postImage">
                      <IconButton
                        color={!submitting ? "primary" : "default"}
                        component="span"
                      >
                        <InsertPhotoIcon className={classes.fileInputButton} />
                      </IconButton>
                    </label>
                  </div>
                </Tooltip>
              ) : !embedHtml ? (
                <Tooltip title="Remove image">
                  <div>
                    <IconButton
                      color={!submitting ? "secondary" : "default"}
                      disabled={submitting}
                      onClick={handleImageRemove}
                    >
                      <CancelIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              ) : null}
              <Tooltip title="Create post">
                <div>
                  <IconButton
                    color={
                      !Boolean(value) || embedHtml || imageFile
                        ? "primary"
                        : "default"
                    }
                    onClick={handleSubmit}
                    disabled={
                      (!embedHtml && !imageFile && !Boolean(value)) ||
                      submitting
                    }
                  >
                    <SendIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Box>
          </Box>
          <Box
            display={imagePreviewLoaded ? "flex" : "none"}
            alignItems="center"
            justifyContent="center"
          >
            <img
              className={classes.previewImage}
              src={imagePreviewData ? imagePreviewData : ""}
              ref={imagePreviewRef as any}
              alt="Selected preview"
            />
          </Box>
          <Box
            display={embedHtml ? "flex" : "none"}
            alignItems="center"
            justifyContent="center"
          >
            <div
              className={classes.embedRoot}
              dangerouslySetInnerHTML={{ __html: embedHtml as string }}
            />
          </Box>
        </CardContent>
      </Card>
      <Dialog open={embedDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Embed Media</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            Paste a Vimeo, YouTube, or Rumble URL to embed media into the next
            post.
          </DialogContentText>
          <Box my="1rem">
            <TextField
              id="embedUrl"
              name="embedUrl"
              onChange={handleDialogChange}
              value={embedValue}
              label="Enter embed URL"
              placeholder="Ex: https://www.youtube.com/watch?v=..."
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={embedLoading}
            onClick={handleEmbedSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={success} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Post created!
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Error creating new post.
        </Alert>
      </Snackbar>
    </>
  );
};

export default PostAdd;
