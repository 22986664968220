import React, { useState, useEffect } from "react";

const useKeyPress = (targetKey: string) => {
  const [keyPressed, setKeyPressed] = useState<boolean>(false);

  // If pressed key is the target, set to true
  const downHandler = ({ key }: React.KeyboardEvent) => {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  };

  // If released key is our target, set to false
  const upHandler = ({ key }: React.KeyboardEvent) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler as any);
    window.addEventListener("keyup", upHandler as any);

    // Remove on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler as any);
      window.removeEventListener("keyup", upHandler as any);
    };
    // eslint-disable-next-line
  }, []);

  return keyPressed;
};

export default useKeyPress;
