import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HamburgerSqueeze } from "react-animated-burgers";
import { AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../assets/rhb-logo-no-bg.png";
import ProfileMenuIcon from "../components/ProfileMenuIcon";
import NavSidebar from "../components/NavSidebar";
import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  logo: {
    maxWidth: 56,
    padding: 4,
    height: "auto",
    transition: "all .2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.02)",
    },
  },
  appBar: {
    alignItems: "left",
    justifyContent: "space-between",
    background: theme.palette.secondary.dark,
    zIndex: theme.zIndex.drawer + 1,
  },
  iconButton: {
    padding: theme.spacing(1),
    color: theme.palette.primary.light,
    "&:hover": {
      background: "rgba(255, 255, 255, 0.2)",
      cursor: "pointer",
      color: "white",
    },
  },
  contentContainer: {
    display: "flex",
    height: "calc(100% - 52px)",
    marginTop: 12,
  },
  content: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F6F8F9",
    overflow: "auto",
  },
  toolbar: theme.mixins.toolbar,
}));

const AppLayout: React.FC = ({ children }) => {
  const classes = useStyles();

  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <HamburgerSqueeze
            isActive={navOpen}
            toggleButton={toggleNav}
            buttonWidth={22}
            barColor="white"
          />
          <Link
            to="/"
            // Icon center fix
            style={{ margin: "8px auto", position: "relative", top: 2 }}
          >
            <img src={logo} alt="RHB Connect logo" className={classes.logo} />
          </Link>
          <ProfileMenuIcon />
        </Toolbar>
      </AppBar>
      <div className={classes.contentContainer}>
        <NavSidebar isOpen={navOpen} toggleIsOpen={toggleNav} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default AppLayout;
