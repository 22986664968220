import axios from "axios";
import config from "../config";
import { InviteData } from "../types/users";

export const getInvites = async () => {
  try {
    const response = await axios.get(
      `${config.API_URL}/users/invites`,
      config.getAxiosJwtHeader()
    );

    return response.data;
  } catch (error) {
    if (error.response.status === 404) {
      throw new Error("No invites found.");
    }

    throw error;
  }
};

export const getInviteByCode = async (inviteCode: string) => {
  try {
    const { data } = await axios.get(
      `${config.API_URL}/users/invites/${inviteCode}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteInviteById = async (id: number) => {
  try {
    return await axios.delete(
      `${config.API_URL}/users/invites/${id}`,
      config.getAxiosJwtHeader()
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createInvite = async (inviteData: InviteData) => {
  try {
    const response = await axios.post(
      `${config.API_URL}/users/invites`,
      inviteData,
      config.getAxiosJwtHeader()
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const resendInviteById = async (id: number) => {
  try {
    const response = await axios.post(
      `${config.API_URL}/users/invites`,
      { id, resendOnly: true },
      config.getAxiosJwtHeader()
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
