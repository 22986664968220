import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Link as MuiLink,
  Divider,
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  Fab,
  Card,
  CardContent,
  IconButton,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import background from "../../assets/auth-bg.jpg";
import logo from "../../assets/rhb-logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import config from "../../config";
import clsx from "clsx";
import MuiPhoneNumber from "material-ui-phone-number";
import CountrySelect from "../../components/Forms/CountrySelect";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    overflow: "auto",
  },
  formContainer: {
    width: "90%",
    padding: 40,
    maxWidth: 500,
    height: "85%",
    backgroundColor: "white",
    overflow: "auto",
  },
  formHeader: {
    textAlign: "center",
  },
  appForm: {
    marginTop: 20,
    marginBottom: 20,
  },
  formField: {
    width: "100%",
    marginBottom: 24,
  },
  phoneField: {
    "&& .MuiInputAdornment-root": {
      display: "none",
    },
  },
  uploadCard: {
    margin: "20px auto",
    padding: 18,
    textAlign: "center",
    width: "75%",
  },
  statusMessage: {
    marginTop: 20,
  },
}));

const AppSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email().required("Email address is required"),
  phone: Yup.string(),
  address1: Yup.string().required("Address is required"),
  address2: Yup.string(),
  city: Yup.string().required("City is required"),
  state: Yup.string(),
  postalCode: Yup.string().required("Postal code is required"),
  country: Yup.string().required("Country is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters long")
    .required("Password is required"),
  passwordConfirm: Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const ApplicationForm: React.FC = () => {
  const classes = useStyles();
  const formRef = useRef();
  const theme = useTheme();

  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [accountExistsError, setAccountExistsError] = useState<boolean>(false);
  const [applicationExistsError, setApplicationExistsError] = useState<boolean>(
    false
  );
  const [idType, setIdType] = useState<string>("");
  const [secondaryFileRequired, setSecondaryFileRequired] = useState<boolean>(
    false
  );
  const [primaryFilePreview, setPrimaryFilePreview] = useState<any>(null);
  const [secondaryFilePreview, setSecondaryFilePreview] = useState<any>(null);

  const primaryImageToURL = (file: File) => {
    const fileReader = new FileReader();
    fileReader.addEventListener("load", function () {
      setPrimaryFilePreview(fileReader.result);
    });
    file && fileReader.readAsDataURL(file);
  };

  const secondaryImageToURL = (file: File) => {
    const fileReader = new FileReader();
    fileReader.addEventListener("load", function () {
      setSecondaryFilePreview(fileReader.result);
    });
    file && fileReader.readAsDataURL(file);
  };

  const packFormData = (data: any) => {
    let formData: any = new FormData();
    for (let key in data) {
      if (!(key === "secondaryFile" && data[key] === null)) {
        formData.append(key, data[key]);
      }
    }
    return formData;
  };

  // Scroll to bottom after relevant selections
  useEffect(() => {
    if (
      idType ||
      success ||
      error ||
      accountExistsError ||
      applicationExistsError
    ) {
      (formRef.current as any).scrollTop = (formRef.current as any).scrollHeight;
    }
  }, [idType, success, error, accountExistsError, applicationExistsError]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      password: "",
      passwordConfirm: "",
      primaryFile: null,
      secondaryFile: null,
    },
    validationSchema: AppSchema,
    onSubmit: (values, { setSubmitting }) => {
      applicationExistsError && setApplicationExistsError(false);
      accountExistsError && setAccountExistsError(false);
      error && setError(false);

      const formData = packFormData(values);
      axios
        .post(`${config.API_URL}/applications`, formData)
        .then((response) => {
          if (response && response.status === 201) {
            setSubmitting(false);
            setSuccess(true);
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 403) {
            setApplicationExistsError(true);
            setSubmitting(false);
          } else if (e.response && e.response.status === 409) {
            setAccountExistsError(true);
            setSubmitting(false);
          } else {
            console.error(e);
            setError(true);
            setSubmitting(false);
          }
        });
    },
  });

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/login"
        style={{ marginBottom: 20 }}
      >
        Back to Login
      </Button>
      <Paper className={classes.formContainer} ref={formRef}>
        <div className={classes.formHeader}>
          <img
            src={logo}
            alt="RHB Connect logo"
            height={140}
            style={{ marginBottom: 30 }}
          />
          <Typography variant="h5" style={{ marginBottom: 30 }}>
            Membership Application
          </Typography>
          <Box mb={3}>
            <Typography variant="body2">
              Welcome to the private site of Dr. Rodney Howard-Browne. To be
              admitted to this site, you must provide proof of identity and a
              valid matching address.
            </Typography>
          </Box>
          <Box mb={4}>
            <Typography variant="body2">
              Your application will be processed as soon as possible. Once your
              application is processed, you will receive an email confirming
              your membership status. Thank you for your patience!
            </Typography>
          </Box>
          <Box textAlign="center" fontStyle="italic">
            <Typography variant="caption">
              Problems with the site? Please reach out to&nbsp;
              <MuiLink href="mailto:support@revival.com">
                support@revival.com
              </MuiLink>
              .
            </Typography>
          </Box>
        </div>
        <Divider style={{ margin: "30px auto" }} />
        <Typography variant="h6" style={{ marginBottom: 30 }}>
          Contact Information
        </Typography>
        <div className={classes.appForm}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              id="firstName"
              type="text"
              variant="outlined"
              className={classes.formField}
              label="First Name"
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              {...formik.getFieldProps("firstName")}
              required
            />
            <TextField
              id="lastName"
              type="text"
              variant="outlined"
              className={classes.formField}
              label="Last Name"
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              {...formik.getFieldProps("lastName")}
              required
            />
            <TextField
              id="email"
              type="text"
              variant="outlined"
              className={classes.formField}
              label="Email Address"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              {...formik.getFieldProps("email")}
              required
            />
            <MuiPhoneNumber
              disableAreaCodes
              label="Phone Number"
              variant="outlined"
              id="phone"
              name="phone"
              className={clsx(
                classes.formField,
                !formik.values.phone && classes.phoneField
              )}
              value={formik.values.phone}
              onChange={(value: string) => {
                formik.setFieldValue("phone", value);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
            <TextField
              id="address1"
              type="text"
              variant="outlined"
              className={classes.formField}
              label="Address"
              error={formik.touched.address1 && Boolean(formik.errors.address1)}
              helperText={formik.touched.address1 && formik.errors.address1}
              {...formik.getFieldProps("address1")}
              required
            />
            <TextField
              id="address2"
              type="text"
              variant="outlined"
              className={classes.formField}
              label="Address 2"
              error={formik.touched.address2 && Boolean(formik.errors.address2)}
              helperText={formik.touched.address2 && formik.errors.address2}
              {...formik.getFieldProps("address2")}
            />
            <TextField
              id="city"
              type="text"
              variant="outlined"
              className={classes.formField}
              label="City"
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              {...formik.getFieldProps("city")}
              required
            />
            <TextField
              id="state"
              type="text"
              variant="outlined"
              className={classes.formField}
              label="State/Province"
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              {...formik.getFieldProps("state")}
            />
            <TextField
              id="postalCode"
              type="text"
              variant="outlined"
              className={classes.formField}
              label="Postal Code"
              error={
                formik.touched.postalCode && Boolean(formik.errors.postalCode)
              }
              helperText={formik.touched.postalCode && formik.errors.postalCode}
              {...formik.getFieldProps("postalCode")}
              required
            />
            <CountrySelect
              label="Country"
              variant="outlined"
              id="country"
              name="country"
              className={classes.formField}
              value={formik.values.country}
              onChange={(value: string) => {
                formik.setFieldValue("country", value);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
            />
            <Divider style={{ margin: "30px auto" }} />
            <Typography variant="h6" style={{ marginBottom: 30 }}>
              Create Password
            </Typography>
            <TextField
              id="password"
              type="password"
              variant="outlined"
              className={classes.formField}
              label="Password"
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              {...formik.getFieldProps("password")}
              required
            />
            <TextField
              id="passwordConfirm"
              type="password"
              variant="outlined"
              className={classes.formField}
              label="Confirm Password"
              error={
                formik.touched.passwordConfirm &&
                Boolean(formik.errors.passwordConfirm)
              }
              helperText={
                formik.touched.passwordConfirm && formik.errors.passwordConfirm
              }
              {...formik.getFieldProps("passwordConfirm")}
              required
            />
            <Divider style={{ margin: "30px auto" }} />
            <Typography variant="h6" style={{ marginBottom: 30 }}>
              ID Upload
            </Typography>
            <Typography>
              We will accept one official state ID, or one of the following with
              an additional photo of your face:
            </Typography>
            <div style={{ margin: "30px 0" }}>
              <ul>
                <li>Utility Bill</li>
                <li>Student ID</li>
              </ul>
            </div>
            <Box m={2} mb={4}>
              <InputLabel id="id-type" style={{ marginBottom: 20 }}>
                Please select the type of ID will you be uploading
              </InputLabel>
              <Select
                labelId="id-type"
                name="id-type"
                id="id-type-select"
                value={idType}
                style={{ width: "100%" }}
                required
                onChange={(e) => {
                  let value: any = e.target.value;

                  if (value) {
                    setIdType(value);
                  }

                  if (value === "student-id" || value === "utility-bill") {
                    setSecondaryFileRequired(true);
                  } else {
                    setSecondaryFileRequired(false);
                  }
                }}
              >
                <MenuItem value="state-dl">State Driver's License</MenuItem>
                <MenuItem value="state-id">Official State ID</MenuItem>
                <MenuItem value="student-id">Student ID</MenuItem>
                <MenuItem value="utility-bill">Utility Bill</MenuItem>
              </Select>
            </Box>
            {idType && (
              <>
                <Box mt={5} mb={3} textAlign="center">
                  <Typography variant="body2">
                    Please upload a photo of your ID below.
                  </Typography>
                </Box>
                <Card className={classes.uploadCard}>
                  <CardContent>
                    {!primaryFilePreview ? (
                      <Typography variant="h6" style={{ marginBottom: 20 }}>
                        Upload Photo
                      </Typography>
                    ) : (
                      <img
                        style={{ marginTop: 20, marginBottom: 20 }}
                        src={primaryFilePreview}
                        alt="Primary preview"
                        width="80"
                      />
                    )}
                    <label htmlFor="primaryFile">
                      <input
                        type="file"
                        style={{ display: "none" }}
                        id="primaryFile"
                        name="primaryFile"
                        required
                        onChange={(e: any) => {
                          let file = e.target.files[0];

                          if (file.name !== null) {
                            var ext = (file.name.match(/\.([^.]+)$/) as any)[1];

                            // Check for valid file extensions
                            switch (ext.toLowerCase()) {
                              case "jpg":
                              case "bmp":
                              case "png":
                              case "heic":
                                formik.setFieldValue(
                                  "primaryFile",
                                  e.target.files[0]
                                );
                                primaryImageToURL(e.target.files[0]);
                                break;
                              default:
                                alert("File type not allowed");
                                e.target.value = "";
                            }
                          }
                        }}
                      />
                      {!formik.values.primaryFile && (
                        <Fab component="span" color="primary">
                          <AddAPhotoIcon />
                        </Fab>
                      )}
                    </label>
                    {formik.values.primaryFile && (
                      <>
                        <IconButton
                          style={{
                            margin: "10px auto",
                            display: "block",
                            color: theme.palette.secondary.main,
                          }}
                          onClick={() => {
                            formik.setFieldValue("primaryFile", null);
                            setPrimaryFilePreview(null);
                          }}
                        >
                          <DeleteIcon fontSize="large" />
                        </IconButton>
                      </>
                    )}
                  </CardContent>
                </Card>
              </>
            )}
            {secondaryFileRequired && (
              <>
                <Box mt={5} mb={3} textAlign="center">
                  <Typography variant="body2">
                    A second photo is required for this type of ID.
                  </Typography>
                </Box>
                <Card className={classes.uploadCard}>
                  <CardContent>
                    {!secondaryFilePreview ? (
                      <Typography variant="h6" style={{ marginBottom: 20 }}>
                        Upload Photo
                      </Typography>
                    ) : (
                      <img
                        style={{ marginTop: 20, marginBottom: 20 }}
                        src={secondaryFilePreview}
                        alt="Secondary preview"
                        width="80"
                      />
                    )}
                    <label htmlFor="secondaryFile">
                      <input
                        type="file"
                        style={{ display: "none" }}
                        id="secondaryFile"
                        name="secondaryFile"
                        required
                        onChange={(e: any) => {
                          let file = e.target.files[0];

                          if (file.name !== null) {
                            var ext = (file.name.match(/\.([^.]+)$/) as any)[1];

                            // Check for valid file extensions
                            switch (ext.toLowerCase()) {
                              case "jpg":
                              case "bmp":
                              case "png":
                                formik.setFieldValue(
                                  "secondaryFile",
                                  e.target.files[0]
                                );
                                secondaryImageToURL(e.target.files[0]);
                                break;
                              default:
                                alert("File type not allowed");
                                e.target.value = "";
                            }
                          }
                        }}
                      />
                      {!formik.values.secondaryFile && (
                        <Fab component="span" color="primary">
                          <AddAPhotoIcon />
                        </Fab>
                      )}
                    </label>
                    {formik.values.secondaryFile && (
                      <>
                        <IconButton
                          style={{
                            margin: "10px auto",
                            display: "block",
                            color: theme.palette.secondary.main,
                          }}
                          onClick={() => {
                            formik.setFieldValue("secondaryFile", null);
                            setSecondaryFilePreview(null);
                          }}
                        >
                          <DeleteIcon fontSize="large" />
                        </IconButton>
                      </>
                    )}
                  </CardContent>
                </Card>
              </>
            )}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              size="large"
              style={{ marginTop: 20 }}
              disabled={
                formik.isSubmitting ||
                success ||
                applicationExistsError ||
                accountExistsError
              }
            >
              {formik.isSubmitting ? "Submitting..." : "Submit"}
            </Button>
            <div className={classes.statusMessage}>
              {error && (
                <Alert severity="error">
                  There was a problem submitting your application.
                </Alert>
              )}
              {accountExistsError && (
                <Alert severity="warning">
                  Your application has already been submitted and approved! You
                  may <MuiLink href="/login">click here</MuiLink> to login.
                </Alert>
              )}
              {applicationExistsError && (
                <Alert severity="warning">
                  Your application has already been submitted! We will review
                  your submission as soon as possible.
                </Alert>
              )}
              {success && (
                <Alert severity="success">
                  Your application was submitted successfully! You will be
                  receiving an email from "RHB Connect" to confirm your
                  submission.
                </Alert>
              )}
            </div>
          </form>
        </div>
      </Paper>
    </div>
  );
};

export default ApplicationForm;
