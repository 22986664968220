import React from "react";
import {
  makeStyles,
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import clsx from "clsx";
import videoPlaceholder from "../assets/video-placeholder.png";
import { MediaItemType } from "../pages/MediaGalleryPage";

type MediaCardProps = {
  className?: string;
  mediaItem: MediaItemType;
  playMedia: (mediaItem: MediaItemType) => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280,
    maxHeight: 360,
  },
  cardMedia: {
    height: 120,
  },
}));

const MediaCard: React.FC<MediaCardProps> = ({
  mediaItem,
  playMedia,
  className,
}) => {
  const classes = useStyles();
  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        onClick={() => {
          playMedia(mediaItem);
        }}
      >
        <CardHeader
          titleTypographyProps={{ variant: "body1" }}
          subheaderTypographyProps={{ variant: "caption" }}
          title={mediaItem?.title}
          subheader={mediaItem?.subtitle}
        />
        <CardMedia image={videoPlaceholder} className={classes.cardMedia} />
        <CardContent>
          <Typography variant="subtitle2">
            {mediaItem?.description
              ? mediaItem.description
              : "No description available."}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default MediaCard;
