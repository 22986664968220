import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import logo from "../assets/rhb-logo.png";
import background from "../assets/auth-bg.jpg";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    overflow: "auto",
  },
  headerLogo: {
    width: 180,
    height: "auto",
  },
  welcomeTextContainer: {
    color: "#fff",
    margin: "20px 0",
    textAlign: "center",
  },
  welcomeHeader: {
    marginBottom: 5,
    fontWeight: "bold",
  },
  welcomeSubheader: {
    fontSize: "1em",
  },
});

const AuthLayout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={logo} alt="RHB Logo" className={classes.headerLogo} />
      <div className={classes.welcomeTextContainer}>
        <Typography variant="h4" className={classes.welcomeHeader}>
          RHB Connect
        </Typography>
        <Typography variant="h6" className={classes.welcomeSubheader}>
          Connect with Dr. Rodney Howard-Browne
        </Typography>
      </div>
      {children}
    </div>
  );
};

export default AuthLayout;
