import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";

type PageHeaderProps = {
  text: string;
  actionButton?: React.ReactNode;
};

const PageHeader: React.FC<PageHeaderProps> = ({ text, actionButton }) => {
  return (
    <>
      <Box mt={4} mb={2} display="flex" justifyContent="space-between">
        <Typography variant="h5">{text}</Typography>
        {actionButton || null}
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
    </>
  );
};

export default PageHeader;
