import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Snackbar,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import MuiPhoneNumber from "material-ui-phone-number";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { updateUser, updateShowMotd } from "../redux/slices/auth";
import PostAdd from "../components/PostAdd";
import axios from "axios";
import config from "../config";
import PostCard from "../components/PostCard";
import { User } from "../types/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  feedContainer: {
    width: "90%",
    maxWidth: 800,
    margin: "0 auto",
  },
  editorContainer: {
    marginTop: 40,
    marginBottom: 40,
    backgroundColor: theme.palette.primary.light,
  },
  postArea: {
    marginTop: 10,
    marginBottom: 10,
  },
  MOTDDialog: {
    maxWidth: 500,
    margin: "0 auto",
  },
  phoneInput: {
    margin: "2rem auto 0 auto",
  },
}));

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const renderPosts = (posts: any, refreshCallback: () => void) => {
  return posts.map(({ post }: any) => {
    return (
      <PostCard post={post} key={post.id} refreshCallback={refreshCallback} />
    );
  });
};

const DashboardPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);

  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState(null);
  const [MOTDDialogOpen, setMOTDDialogOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneInvalidError, setPhoneInvalidError] = useState(false);
  const [phoneSubmitting, setPhoneSubmitting] = useState(false);
  const [phoneSubmitError, setPhoneSubmitError] = useState(false);
  const [phoneSubmitSuccess, setPhoneSubmitSuccess] = useState(false);
  const [noPrompt, setNoPrompt] = useState(false);
  const [noPromptConfirm, setNoPromptConfirm] = useState(false);

  const handleDialogClose = () => {
    setMOTDDialogOpen(false);
    dispatch(updateShowMotd(false));
  };

  const handlePhoneInput = (value: string) => {
    setPhoneNumber(value);
  };

  const handlePhoneSubmit = async () => {
    phoneSubmitError && setPhoneSubmitError(false);
    phoneInvalidError && setPhoneInvalidError(false);

    if (phoneNumber.length < 8) {
      setPhoneInvalidError(true);
      return;
    }

    setPhoneSubmitting(true);

    try {
      const result = await axios.patch(
        `${config.API_URL}/users/${(auth.user as User).id}/phone`,
        { phoneNumber },
        config.getAxiosJwtHeader()
      );

      if (result && result.status === 200) {
        setPhoneSubmitting(false);
        setMOTDDialogOpen(false);
        setPhoneSubmitSuccess(true);
        // Update the user phone and prompt state
        dispatch(updateShowMotd(false));
        dispatch(
          updateUser({
            ...auth.user,
            phone_number: result.data.phone_number,
          } as User)
        );
      }
    } catch (e) {
      setPhoneSubmitting(false);
      setPhoneSubmitError(true);
      console.error(e.message);
    }
  };

  const handleNoPromptChange = () => {
    setNoPrompt(!noPrompt);
  };

  const handleNoPromptSubmit = async () => {
    setMOTDDialogOpen(false);
    dispatch(updateShowMotd(false));
    setNoPromptConfirm(true);
    try {
      const result = await axios.patch(
        `${config.API_URL}/users/${(auth.user as User).id}/show-motd`,
        { value: false },
        config.getAxiosJwtHeader()
      );

      if (result && result.status === 200) {
        console.log("Got it! We won't bother you again.");
      } else {
        console.error("Problem updating MOTD display status.");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getPosts = async () => {
    try {
      const response = await axios.get(
        `${config.API_URL}/posts`,
        config.getAxiosJwtHeader()
      );

      if (response && response.status === 200) {
        setPosts(response.data);
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
      if (e.response.status === 404) {
        console.log("No posts available.");
        setPosts(null);
        setIsLoading(false);
      } else {
        console.error(e.response);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getPosts();
    // Testing the showMOTD variable
    auth.user?.show_motd && setMOTDDialogOpen(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (phoneSubmitSuccess) {
      setTimeout(() => {
        setPhoneSubmitSuccess(false);
      }, 3000);
    }

    if (phoneSubmitError) {
      setTimeout(() => {
        setPhoneSubmitError(false);
      }, 3000);
    }

    if (noPromptConfirm) {
      setTimeout(() => {
        setNoPromptConfirm(false);
      }, 3000);
    }
  });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.feedContainer}>
          {((auth.user as User).role === "Administrator" ||
            (auth.user as User).role === "RHB") && (
            <>
              <div className={classes.editorContainer}>
                <PostAdd addCallback={getPosts} />
              </div>
              <Divider style={{ marginBottom: "2.4rem" }} />
            </>
          )}
          <div className={classes.postArea}>
            {!isLoading ? (
              posts ? (
                <>
                  {renderPosts(posts, getPosts)}
                  <div style={{ marginTop: 40 }} />
                </>
              ) : (
                <Typography style={{ textAlign: "center" }}>
                  No posts available.
                </Typography>
              )
            ) : (
              <Typography style={{ textAlign: "center", marginTop: 20 }}>
                Loading posts...
              </Typography>
            )}
          </div>
        </div>
      </div>
      <Dialog
        className={classes.MOTDDialog}
        open={MOTDDialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>Welcome to the new RHB Connect!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="subtitle2">
              This site is currently being developed and refined for a much
              better experience. Exclusive media and regular posts are coming
              soon! You can get{" "}
              <Link to="/about" onClick={() => dispatch(updateShowMotd(false))}>
                more information on the update here
              </Link>
              .
            </Typography>
            {!auth.user?.phone_number && (
              <Box mt={4}>
                <Typography variant="subtitle2">
                  Enter or update your phone number below to receive important
                  text updates from Pastor Rodney.
                </Typography>
                <MuiPhoneNumber
                  disableAreaCodes
                  defaultCountry="us"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={classes.phoneInput}
                  onChange={handlePhoneInput}
                  disabled={noPrompt}
                  error={phoneInvalidError}
                  helperText={
                    phoneInvalidError && "Invalid phone number length"
                  }
                />
              </Box>
            )}
            <FormGroup row style={{ marginTop: "1rem" }}>
              <FormControlLabel
                style={{ userSelect: "none" }}
                control={
                  <Checkbox
                    color="primary"
                    checked={noPrompt}
                    onChange={handleNoPromptChange}
                  />
                }
                label="Please do not show me this again"
              />
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        {noPrompt ? (
          <DialogActions>
            <Button onClick={handleNoPromptSubmit} color="primary">
              Close
            </Button>
          </DialogActions>
        ) : auth.user?.phone_number ? (
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              color="secondary"
              disabled={phoneSubmitting}
            >
              Not Now
            </Button>
            <Button
              onClick={handlePhoneSubmit}
              color="primary"
              disabled={phoneSubmitting}
            >
              Update
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Snackbar open={phoneSubmitSuccess}>
        <Alert severity="success">Phone number updated!</Alert>
      </Snackbar>
      <Snackbar open={phoneSubmitError}>
        <Alert severity="error">
          There was a problem updating your phone number.
        </Alert>
      </Snackbar>
      <Snackbar open={noPromptConfirm}>
        <Alert severity="success">
          Got it! We won't show you again until the next update.
        </Alert>
      </Snackbar>
    </>
  );
};

export default DashboardPage;
