import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { MediaItemType } from "../pages/MediaGalleryPage";
import clsx from "clsx";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "../vendor/videojs/vjs-rhb.css";
import config from "../config";

const useStyles = makeStyles((theme) => ({
  root: {},
  player: {
    height: "80%",
    width: "80%",
    maxHeight: 400,
    maxWidth: 600,
  },
}));

type ModalVideoPlayerProps = {
  mediaItem: MediaItemType;
};

const ModalVideoPlayer: React.FC<ModalVideoPlayerProps> = ({ mediaItem }) => {
  const classes = useStyles();
  const playerRef: any = useRef();

  // Initialize the player
  useEffect(() => {
    const player = videojs(
      playerRef.current,
      { autoplay: false, muted: false, controls: true },
      () => {
        player.src(`${config.CDN_VIDEO_URL + mediaItem.filename}`);
      }
    );

    return () => {
      player.dispose();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={classes.root}
      data-vjs-player
      onClick={(e) => e.stopPropagation()}
    >
      <video
        ref={playerRef}
        className={clsx(classes.player, "vjs-rhb", "video-js")}
      />
    </div>
  );
};

export default ModalVideoPlayer;
