import React, { useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert as MuiAlert, AlertProps } from "@material-ui/lab";

type AlertToastProps = {
  open: boolean;
  onClose: () => void;
  severity: "success" | "info" | "warning" | "error";
  text: string;
  duration?: number;
};

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const AlertToast: React.FC<AlertToastProps> = ({
  open,
  onClose,
  duration,
  severity,
  text,
}) => {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        onClose();
      }, duration || 3000);
    }
  });

  return (
    <Snackbar open={open}>
      <Alert severity={severity}>{text}</Alert>
    </Snackbar>
  );
};

export default AlertToast;
