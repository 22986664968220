import React from "react";
import { makeStyles, Typography, Box, Paper, Link } from "@material-ui/core";
import PageHeader from "../components/PageHeader";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    maxWidth: 1200,
    height: "100%",
  },
  content: {
    width: "98%",
    maxWidth: 800,
    margin: "16px auto",
    padding: 40,
  },
}));

const AboutPage: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <PageHeader text="About This App" />
      <Paper className={classes.content}>
        <Box mb={8} style={{ textAlign: "center" }}>
          <Typography variant="h5">Welcome to the new RHB Connect!</Typography>
        </Box>
        <Typography>
          This is an all-new platform built to better fulfill the vision of Dr.
          Rodney Howard-Browne and provide an outlet to reach people that is not
          subject to the whim of "Big Tech".
        </Typography>
        <Box mt={4}>
          <Typography variant="h6">What's up with the new look?</Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            The application that you are currently using is a complete rebuild
            to address the shortcomings of the previous version and provide a
            foundation to build out new features over time. We appreciate
            everyone's patience during this process, and we are excited to be
            creating a unique platform that allows Dr. Rodney to provide content
            you won't be able to access anywhere else.
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography>
            Here are some planned updates that you will see coming together in
            the coming weeks:
          </Typography>
          <ul>
            <li>
              <Typography>
                A smoother and more transparent application approval process
              </Typography>
            </li>
            <li>
              <Typography>
                User experience improvements including the ability to post
                images in comments
              </Typography>
            </li>
            <li>
              <Typography>
                Exclusive on-demand media content and giveaway events
              </Typography>
            </li>
            <li>
              <Typography>
                An exclusive live stream with chat capabilities
              </Typography>
            </li>
            <li>
              <Typography>
                Continual interface improvements for a more modern look and feel
              </Typography>
            </li>
          </ul>
        </Box>
        <Box mt={8} textAlign="center">
          <Typography>
            If you haven't updated your profile information yet, please{" "}
            <Link href="/profile">do so here</Link> to stay updated.
          </Typography>
        </Box>
        <Box mt={8} fontStyle="italic">
          <Typography variant="subtitle2" style={{ textAlign: "center" }}>
            If you have any questions or concerns, please email{" "}
            <Link href="mailto:support@revival.com">support@revival.com</Link>.
          </Typography>
        </Box>
      </Paper>
    </div>
  );
};

export default AboutPage;
