import axios from "axios";
import config from "../config";

export const fetchEmbedData = async (url: string) => {
  try {
    const { data } = await axios.get(
      `${config.API_URL}/services/oembed?url=${url}`,
      config.getAxiosJwtHeader()
    );

    if (data.html) {
      return data;
    }

    return null;
  } catch (error) {
    throw error;
  }
};
