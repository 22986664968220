import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import DashboardRouter from "../routes/DashboardRouter";

import AppLayout from "../layouts/AppLayout";
import AuthLayout from "../layouts/AuthLayout";
import LoginForm from "../components/Forms/LoginForm";
import ResetForm from "../components/Forms/ResetForm";
import ResetTokenForm from "../components/Forms/ResetTokenForm";
import ApplicationForm from "../components/Application/ApplicationForm";
import NotFound from "../components/NotFound";
import LoadingSpinner from "../components/LoadingSpinner";
import { useAppSelector } from "../redux/hooks";
import InviteRegistrationForm from "../components/Forms/InviteRegistrationForm";

const AppRouter: React.FC = () => {
  const auth = useAppSelector((state) => state.auth);

  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          {auth.status === "loading" ? (
            <LoadingSpinner />
          ) : auth.user === null ? (
            <AuthLayout>
              <LoginForm />
            </AuthLayout>
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        <Route exact path="/reset">
          {auth.status === "loading" ? (
            <LoadingSpinner />
          ) : auth.user === null ? (
            <AuthLayout>
              <ResetForm />
            </AuthLayout>
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        <Route exact path="/reset-pass">
          <AuthLayout>
            <ResetTokenForm />
          </AuthLayout>
        </Route>
        <Route exact path="/register">
          <InviteRegistrationForm />
        </Route>
        <Route exact path="/apply">
          {auth.status === "loading" ? (
            <LoadingSpinner />
          ) : auth.user === null ? (
            <ApplicationForm />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        <Route exact path="/not-found">
          <NotFound />
        </Route>
        <Route path="/">
          {auth.status === "loading" ? (
            <LoadingSpinner />
          ) : auth.user === null ? (
            <Redirect to="/login" />
          ) : (
            <AppLayout>
              <DashboardRouter />
            </AppLayout>
          )}
        </Route>
      </Switch>
    </Router>
  );
};

export default AppRouter;
