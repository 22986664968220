import React, { useEffect } from "react";
import { makeStyles, Typography, CircularProgress } from "@material-ui/core";
import PageHeader from "../components/PageHeader";
import InvitesTable from "../components/InvitesTable";
import InviteDialog from "../components/Forms/Invites/InviteDialog";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { fetchInvites } from "../redux/slices/invites";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    maxWidth: 1200,
    height: "100%",
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const AddUserPage = () => {
  const classes = useStyles();
  const invites = useAppSelector((state) => state.invites);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchInvites());
  }, []);

  return (
    <div className={classes.root}>
      <PageHeader text="User Invites" actionButton={<InviteDialog />} />
      {invites.status === "loading" ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <InvitesTable />
      )}
      {/* <InviteUserForm /> */}
    </div>
  );
};

export default AddUserPage;
