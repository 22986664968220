import React, { useState, useRef } from "react";
import { Box, Paper, Typography, makeStyles, Button } from "@material-ui/core";
import Avatar from "react-avatar-edit";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import axios from "axios";
import config from "../config";
import { updateUser } from "../redux/slices/auth";
import { User } from "../types/auth";

const useStyles = makeStyles((theme) => ({
  profileForm: {
    display: "flex",
    flexDirection: "column",
  },
  formPaper: {
    margin: "40px auto 20px auto",
    width: "98%",
    maxWidth: 600,
    padding: 30,
  },
  alert: {
    width: "80%",
    margin: "20px auto",
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  previewImg: {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "0 auto",
  },
}));

const AvatarUpload: React.FC = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const avatarRef: any = useRef();

  const [croppedImg, setCroppedImg] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleCropChange = (img: any) => {
    setCroppedImg(img);
  };

  const handleCropperClose = () => {
    setCroppedImg("");
  };

  const handleSuccess = () => {
    if (avatarRef?.current) {
      delete avatarRef.current.state.file;
      delete avatarRef.current.state.image;
      avatarRef.current.state.showLoader = true;
    }

    setSuccess(true);
  };

  const handleAvatarUpload = () => {
    setSubmitting(true);
    axios
      .patch(
        `${config.API_URL}/users/${auth.user?.id}/avatar`,
        { avatar: croppedImg },
        config.getAxiosJwtHeader()
      )
      .then((response) => {
        setSubmitting(false);
        if (response.status === 200) {
          dispatch(
            updateUser({ ...auth.user, avatar: response.data.url } as User)
          );
          handleSuccess();
        } else {
          setError(true);
          setSubmitting(false);
        }
      })
      .catch((e) => {
        setError(true);
        setSubmitting(false);
        console.error(e.response);
      });
  };

  return (
    <>
      <Paper className={classes.formPaper}>
        <Typography
          variant="h5"
          style={{ marginBottom: 30, textAlign: "center" }}
        >
          Profile Image
        </Typography>
        <form onSubmit={handleAvatarUpload} className={classes.profileForm}>
          <div className={classes.avatarContainer}>
            <Avatar
              label="Choose an image"
              width={280}
              height={280}
              onCrop={handleCropChange}
              onClose={handleCropperClose}
              src={croppedImg}
              ref={avatarRef}
            />
          </div>
          <Box mt={3} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              disabled={submitting || !croppedImg}
              onClick={handleAvatarUpload}
            >
              {submitting ? "Uploading..." : "Upload"}
            </Button>
            {error && (
              <Box mt={2}>
                <Typography variant="subtitle2" color="secondary">
                  There was a problem uploading your image.
                </Typography>
              </Box>
            )}
            {success && (
              <Box mt={2} color="green">
                <Typography variant="subtitle2">
                  Your avatar was successfully updated.
                </Typography>
              </Box>
            )}
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default AvatarUpload;
