import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  FormGroup,
  CircularProgress,
  Link as MuiLink,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import config from "../../config";

type UserDataType = {
  id: number;
  first_name: string;
  last_name: string;
  email_address: string;
};

type FormStateType = {
  password: string;
  passwordConfirm: string;
};

const useStyles = makeStyles((theme) => ({
  rootPaper: { padding: 20, minWidth: 400 },
  submitButton: {
    margin: "5px 0",
  },
}));

const ResetTokenForm: React.FC = () => {
  const classes = useStyles();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");

  // Form errors
  const [notFoundError, setNotFoundError] = useState<boolean>(false);
  const [notValidError, setNotValidError] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<boolean>(false);

  // Form status state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);

  // User and form state
  const [userData, setUserData] = useState<UserDataType | null>(null);
  const [formState, setFormState] = useState<FormStateType>({
    password: "",
    passwordConfirm: "",
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      formState.password.length > 4 &&
      formState.password === formState.passwordConfirm
    ) {
      submitForm();
    } else {
      setNotValidError(true);
    }
  };

  const submitForm = async () => {
    setIsSubmitting(true);
    if (userData) {
      try {
        const response = await axios.patch(
          `${config.API_URL}/users/${userData.id}/password`,
          { token: token, password: formState.password }
        );

        if (response && response.status === 200) {
          setIsSubmitting(false);
          setIsSuccessful(true);
        } else {
          setIsSubmitting(false);
          setSubmissionError(true);
        }
      } catch (e) {
        console.error(e.response);
        setSubmissionError(true);
      }
    } else {
      console.error("A critical error has occurred, no userData present.");
      return;
    }
  };

  const handleInputChange = (e: React.FormEvent) => {
    notValidError && setNotValidError(false);
    const target = e.target as HTMLTextAreaElement;
    setFormState({ ...formState, [target.name]: target.value });
  };

  // Check token on initial load
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${config.API_URL}/users/reset/${token}`
        );
        if (response && response.status === 200) {
          setUserData({
            id: response.data.id,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            email_address: response.data.email_address,
          });
          setIsLoading(false);
        } else {
          console.error("Response not received.");
          return null;
        }
      } catch (e) {
        if (e.response.status === 404) {
          setNotFoundError(true);
        }
        console.error(e.response);
        return null;
      }
    };
    checkToken();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {});

  return token && !notFoundError ? (
    !isLoading ? (
      <Paper className={classes.rootPaper}>
        <form onSubmit={handleSubmit}>
          <Box textAlign="center">
            <Typography variant="h5">
              Welcome {userData && userData.first_name}!
            </Typography>
            {!isSuccessful && (
              <Box mt={2} mb={4}>
                <Typography>Please enter your new password below.</Typography>
              </Box>
            )}
          </Box>
          <FormGroup>
            {!isSuccessful && (
              <>
                <TextField
                  style={{ marginBottom: 18 }}
                  id="password"
                  name="password"
                  label="New Password"
                  variant="outlined"
                  type="password"
                  helperText="Enter your new password"
                  onChange={handleInputChange}
                  autoFocus
                  required
                />
                <TextField
                  style={{ marginBottom: 18 }}
                  id="passwordConfirm"
                  name="passwordConfirm"
                  label="Confirm Password"
                  variant="outlined"
                  type="password"
                  helperText="Enter your new password again"
                  onChange={handleInputChange}
                  required
                />
              </>
            )}
            {isSubmitting ? (
              <Button className={classes.submitButton} size="large" disabled>
                Submitting...
              </Button>
            ) : (
              !isSuccessful && (
                <Button
                  variant="contained"
                  className={classes.submitButton}
                  size="large"
                  type="submit"
                  color="primary"
                >
                  Submit
                </Button>
              )
            )}
            {notValidError && (
              <Alert severity="error" style={{ margin: "12px 0 12px 0" }}>
                Passwords must match and be at least 5 letters long.
              </Alert>
            )}
            {submissionError && (
              <Alert severity="error" style={{ margin: "12px 0 12px 0" }}>
                There was a problem resetting your password.
              </Alert>
            )}
            {isSuccessful && (
              <Alert severity="success" style={{ margin: "12px 0 12px 0" }}>
                Password updated!{" "}
                <MuiLink href="/login">Click here to login</MuiLink>
              </Alert>
            )}
          </FormGroup>
        </form>
      </Paper>
    ) : (
      <Paper className={classes.rootPaper}>
        <Box my={4} textAlign="center">
          <Typography variant="h6" style={{ marginBottom: 22 }}>
            Checking link, please wait...
          </Typography>
          <CircularProgress size={50} />
        </Box>
      </Paper>
    )
  ) : (
    <Box color="white" textAlign="center" my={4}>
      <Typography variant="h5">Invalid link.</Typography>
    </Box>
  );
};

export default ResetTokenForm;
