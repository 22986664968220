import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import config from "../../config";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

type ApplicationViewerProps = {
  appData: ApplicationRecordType;
  approveCallback: () => void;
  denyCallback: () => void;
  disabled: boolean;
};

type ApplicationRecordType = {
  id: number;
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number: string;
  address_1: string;
  address_2?: string | null;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  submission_primary: string;
  submission_secondary?: string | null;
};

const useStyles = makeStyles((theme) => ({
  appPaper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: 40,
    marginBottom: 60,
    width: "80%",
    maxWidth: 500,
  },
  imagePlaceholder: {
    display: "flex",
    width: 200,
    height: 200,
    alignItems: "center",
    justifyContent: "center",
  },
  previewImage: {
    margin: "0px auto",
    width: 200,
    height: "auto",
    "&:hover": {
      cursor: "pointer",
      boxShadow: `0 0 8px ${theme.palette.secondary.main}`,
    },
    borderRadius: 5,
  },
  userInfoContainer: {
    margin: "30px auto 20px auto",
  },
  buttonRow: {
    marginTop: 20,
    height: 100,
    display: "flex",
    flexDirection: "column",
    alignItems: "space-around",
    justifyContent: "center",
  },
}));

const printAppInfo = (appData: ApplicationRecordType) => {
  let addressString = appData.address_1;
  let cityStateString = "";

  if (appData.address_2) {
    addressString += ` ${appData.address_2}`;
  }

  if (appData.city) {
    cityStateString += appData.city;
  }

  if (appData.state) {
    cityStateString += appData.city ? ` ${appData.state}` : appData.state;
  }

  if (appData.postal_code) {
    cityStateString += ` ${appData.postal_code}`;
  }

  return (
    <div style={{ textAlign: "center" }}>
      <Typography
        variant="h5"
        style={{ marginBottom: 10 }}
      >{`${appData.first_name} ${appData.last_name}`}</Typography>
      <Typography style={{ marginBottom: 10 }}>{addressString}</Typography>
      <Typography>{cityStateString}</Typography>
    </div>
  );
};

const ApplicationViewer: React.FC<ApplicationViewerProps> = ({
  appData,
  approveCallback,
  denyCallback,
  disabled,
}) => {
  const classes = useStyles();
  const [app, setApp] = useState<ApplicationRecordType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [primaryLightboxActive, setPrimaryLightboxActive] = useState<boolean>(
    false
  );
  const [
    secondaryLightboxActive,
    setSecondaryLightboxActive,
  ] = useState<boolean>(false);

  const togglePrimaryLightboxActive = () => {
    setPrimaryLightboxActive(!primaryLightboxActive);
  };

  const toggleSecondaryLightboxActive = () => {
    setSecondaryLightboxActive(!secondaryLightboxActive);
  };

  useEffect(() => {
    if (appData) {
      !isLoading && setIsLoading(true);
      axios
        .get(
          `${config.API_URL}/applications/${appData.id}`,
          config.getAxiosJwtHeader()
        )
        .then((response) => {
          setApp(response.data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.error(e.response);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [appData]);

  return (
    <>
      {isLoading ? (
        <div className={classes.imagePlaceholder}>
          <Typography>Loading image...</Typography>
        </div>
      ) : (
        <Paper className={classes.appPaper}>
          <img
            className={classes.previewImage}
            src={(app as ApplicationRecordType).submission_primary}
            alt="Verification submission"
            onClick={togglePrimaryLightboxActive}
          />
          {appData.submission_secondary && (
            <img
              className={classes.previewImage}
              style={{ marginTop: 30 }}
              src={(app as ApplicationRecordType).submission_secondary as any}
              alt="Verification secondary submission"
              onClick={toggleSecondaryLightboxActive}
            />
          )}
          <div className={classes.userInfoContainer}>
            {printAppInfo(app as ApplicationRecordType)}
          </div>
          {disabled ? (
            <Box textAlign="center" marginTop={2}>
              <CircularProgress />
            </Box>
          ) : (
            <div className={classes.buttonRow}>
              <Button
                style={{
                  marginBottom: 28,
                }}
                variant="contained"
                color="primary"
                size="large"
                onClick={approveCallback}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={denyCallback}
              >
                Deny
              </Button>
            </div>
          )}
        </Paper>
      )}
      {primaryLightboxActive && (app as any).submission_primary && (
        <Lightbox
          image={(app as any).submission_primary}
          onClose={togglePrimaryLightboxActive}
        />
      )}
      {secondaryLightboxActive && (app as any).submission_secondary && (
        <Lightbox
          image={(app as any).submission_secondary}
          onClose={toggleSecondaryLightboxActive}
        />
      )}
    </>
  );
};

export default ApplicationViewer;
