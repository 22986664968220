import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Button,
  TextField,
  FormGroup,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import config from "../../config";

const useStyles = makeStyles({
  formHeaderText: {
    marginBottom: 30,
    textAlign: "center",
  },
  loginContainer: {
    padding: 20,
    minWidth: 400,
  },
  formTextField: {
    marginBottom: 20,
  },
  submitButton: {
    margin: "5px 0",
  },
  feedbackContainer: {
    textAlign: "center",
    margin: "20px auto",
    maxWidth: 380,
  },
  otherActionsContainer: {
    marginTop: 12,
  },
  otherActionsFirst: {
    marginBottom: 4,
  },
});

const ResetForm: React.FC = () => {
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionFailed, setSubmissionFailed] = useState(false);
  const [userNotFoundError, setUserNotFoundError] = useState(false);
  const [email, setEmail] = useState("");

  const handleInputChange = (e: React.FormEvent) => {
    setEmail((e.target as HTMLTextAreaElement).value);
  };

  const submitForm = async (email: string) => {
    const request = JSON.stringify({ email });
    const url = `${config.API_URL}/users/reset`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: request,
    });

    return response.json();
  };

  const resetFormStatus = () => {
    setSubmissionSuccess(false);
    setSubmissionFailed(false);
    setUserNotFoundError(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    resetFormStatus();
    e.preventDefault();
    setIsSubmitting(true);
    const response = await submitForm(email);

    if (response && response.status === "Success") {
      setIsSubmitting(false);
      setSubmissionSuccess(true);
    } else if (response && response.status === "404 Not Found") {
      setIsSubmitting(false);
      setUserNotFoundError(true);
    } else {
      setIsSubmitting(false);
      setSubmissionFailed(true);
    }
  };

  return (
    <>
      <Paper className={classes.loginContainer}>
        {!submissionSuccess && (
          <Typography variant="h4" className={classes.formHeaderText}>
            Reset Password
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          {!submissionSuccess && (
            <FormGroup>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                helperText="Enter your email address to receive a reset link"
                className={classes.formTextField}
                onChange={handleInputChange}
                autoFocus
                required
              />
            </FormGroup>
          )}
          {isSubmitting ? (
            <Button className={classes.submitButton} size="large" disabled>
              Submitting...
            </Button>
          ) : (
            !submissionSuccess && (
              <Button
                variant="contained"
                color="primary"
                className={classes.submitButton}
                size="large"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </Button>
            )
          )}
          {(submissionSuccess || submissionFailed || userNotFoundError) && (
            <div className={classes.feedbackContainer}>
              {submissionSuccess && (
                <Alert color="success" style={{ marginTop: -20 }}>
                  Reset request successful! You should receive an email with a
                  link to reset your password.
                </Alert>
              )}
              {submissionFailed && (
                <Alert color="error">
                  Reset request failed! Please try again later.
                </Alert>
              )}
              {userNotFoundError && (
                <Alert color="warning">
                  Cannot reset password, email not found!
                </Alert>
              )}
            </div>
          )}
        </form>
        <div className={classes.otherActionsContainer}>
          <Typography variant="body2" className={classes.otherActionsFirst}>
            Already have an account? <Link to="/login">Login here.</Link>
          </Typography>
          <Typography variant="body2">
            Need an account? <Link to="/apply">Apply for admission.</Link>
          </Typography>
        </div>
      </Paper>
    </>
  );
};

export default ResetForm;
