import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Link as MuiLink,
  Divider,
  TextField,
  Button,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import background from "../../assets/auth-bg.jpg";
import logo from "../../assets/rhb-logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import MuiPhoneNumber from "material-ui-phone-number";
import CountrySelect from "../../components/Forms/CountrySelect";
import { getInviteByCode } from "../../services/invites";
import { Invite } from "../../types/users";
import { createAccount } from "../../services/users";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    overflow: "auto",
  },
  formContainer: {
    width: "90%",
    padding: 40,
    maxWidth: 500,
    height: "85%",
    backgroundColor: "white",
    overflow: "auto",
  },
  formHeader: {
    textAlign: "center",
  },
  appForm: {
    marginTop: 20,
    marginBottom: 20,
  },
  formField: {
    width: "100%",
    marginBottom: 24,
  },
  phoneField: {
    "&& .MuiInputAdornment-root": {
      display: "none",
    },
  },
  statusMessage: {
    marginTop: 20,
  },
});

const InviteRegistrationForm: React.FC = () => {
  const classes = useStyles();
  const formRef = useRef();

  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");

  const [inviteData, setInviteData] = useState<Invite | null>(null);

  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!token) {
      history.push("/login");
    }

    const getInvite = async () => {
      try {
        const inviteData = await getInviteByCode(token as string);

        setInviteData(inviteData);
      } catch (error) {
        console.error(error);
      }
    };

    getInvite();
    // eslint-disable-next-line
  }, []);

  // Scroll to bottom after relevant selections and redirect on success
  useEffect(() => {
    if (success || error) {
      (formRef.current as any).scrollTop = (formRef.current as any).scrollHeight;
    }

    if (success) {
      setTimeout(() => {
        history.push("/login");
      }, 5000);
    }
    // eslint-disable-next-line
  }, [success, error]);

  const formatRole = (role: string) => {
    return role === "Administrator"
      ? `an administrator`
      : role === "Member"
      ? `a member`
      : "a moderator";
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      invitedById: inviteData?.invited_by_id.toString() || "",
      userRoleId: inviteData?.user_role_id.toString() || "",
      firstName: inviteData?.first_name || "",
      lastName: inviteData?.last_name || "",
      email: inviteData?.email_address || "",
      phone: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      password: "",
      passwordConfirm: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string().email().required("Email address is required"),
      phone: Yup.string(),
      address1: Yup.string(),
      address2: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      postalCode: Yup.string(),
      country: Yup.string(),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters long")
        .required("Password is required"),
      passwordConfirm: Yup.string()
        .required("Password confirmation is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      error && setError(null);

      try {
        const userData = await createAccount(values);

        userData ? setSuccess(true) : setError("No user data returned.");
        setSubmitting(false);
      } catch (error) {
        setError(error.message);
        setSubmitting(false);
      }
    },
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.formContainer} ref={formRef}>
        <div className={classes.formHeader}>
          <img
            src={logo}
            alt="RHB Connect logo"
            height={140}
            style={{ marginBottom: 30 }}
          />
          <Typography variant="h5" style={{ marginBottom: 30 }}>
            Register Account
          </Typography>
          <Box mb={3}>
            <Typography variant="body2">
              {`Welcome to Dr. Rodney Howard-Browne's private site. You have been invited as ${formatRole(
                inviteData?.user_role as string
              )}. Please fill out your information below to access exclusive content!`}
            </Typography>
          </Box>
          <Box textAlign="center" fontStyle="italic">
            <Typography variant="caption">
              Problems with the site? Please reach out to&nbsp;
              <MuiLink href="mailto:support@revival.com">
                support@revival.com
              </MuiLink>
              .
            </Typography>
          </Box>
        </div>
        <Divider style={{ margin: "30px auto" }} />
        <Typography variant="h6" style={{ marginBottom: 30 }}>
          Contact Information
        </Typography>
        <div className={classes.appForm}>
          {inviteData ? (
            <form onSubmit={formik.handleSubmit}>
              <TextField
                id="firstName"
                type="text"
                variant="outlined"
                className={classes.formField}
                label="First Name"
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                {...formik.getFieldProps("firstName")}
                required
              />
              <TextField
                id="lastName"
                type="text"
                variant="outlined"
                className={classes.formField}
                label="Last Name"
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                {...formik.getFieldProps("lastName")}
                required
              />
              <TextField
                id="email"
                type="text"
                variant="outlined"
                className={classes.formField}
                label="Email Address"
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                {...formik.getFieldProps("email")}
                required
              />
              <MuiPhoneNumber
                disableAreaCodes
                label="Phone Number"
                variant="outlined"
                id="phone"
                name="phone"
                className={clsx(
                  classes.formField,
                  !formik.values.phone && classes.phoneField
                )}
                value={formik.values.phone}
                onChange={(value: string) => {
                  formik.setFieldValue("phone", value);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
              <TextField
                id="address1"
                type="text"
                variant="outlined"
                className={classes.formField}
                label="Address"
                error={
                  formik.touched.address1 && Boolean(formik.errors.address1)
                }
                helperText={formik.touched.address1 && formik.errors.address1}
                {...formik.getFieldProps("address1")}
              />
              <TextField
                id="address2"
                type="text"
                variant="outlined"
                className={classes.formField}
                label="Address 2"
                error={
                  formik.touched.address2 && Boolean(formik.errors.address2)
                }
                helperText={formik.touched.address2 && formik.errors.address2}
                {...formik.getFieldProps("address2")}
              />
              <TextField
                id="city"
                type="text"
                variant="outlined"
                className={classes.formField}
                label="City"
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
                {...formik.getFieldProps("city")}
              />
              <TextField
                id="state"
                type="text"
                variant="outlined"
                className={classes.formField}
                label="State/Province"
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
                {...formik.getFieldProps("state")}
              />
              <TextField
                id="postalCode"
                type="text"
                variant="outlined"
                className={classes.formField}
                label="Postal Code"
                error={
                  formik.touched.postalCode && Boolean(formik.errors.postalCode)
                }
                helperText={
                  formik.touched.postalCode && formik.errors.postalCode
                }
                {...formik.getFieldProps("postalCode")}
              />
              <CountrySelect
                label="Country"
                variant="outlined"
                id="country"
                name="country"
                className={classes.formField}
                value={formik.values.country}
                onChange={(value: string) => {
                  formik.setFieldValue("country", value);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              />
              <Divider style={{ margin: "30px auto" }} />
              <Typography variant="h6" style={{ marginBottom: 30 }}>
                Create Password
              </Typography>
              <TextField
                id="password"
                type="password"
                variant="outlined"
                className={classes.formField}
                label="Password"
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                {...formik.getFieldProps("password")}
                required
              />
              <TextField
                id="passwordConfirm"
                type="password"
                variant="outlined"
                className={classes.formField}
                label="Confirm Password"
                error={
                  formik.touched.passwordConfirm &&
                  Boolean(formik.errors.passwordConfirm)
                }
                helperText={
                  formik.touched.passwordConfirm &&
                  formik.errors.passwordConfirm
                }
                {...formik.getFieldProps("passwordConfirm")}
                required
              />
              <Divider style={{ margin: "30px auto" }} />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                size="large"
                style={{ marginTop: 20 }}
                disabled={formik.isSubmitting || success}
              >
                {formik.isSubmitting ? "Submitting..." : "Submit"}
              </Button>
              <div className={classes.statusMessage}>
                {error && (
                  <Alert severity="error">
                    There was a problem creating your account.
                  </Alert>
                )}
                {success && (
                  <Alert severity="success">
                    Your account was created successfully! Redirecting to login
                    in 5 seconds...
                  </Alert>
                )}
              </div>
            </form>
          ) : null}
        </div>
      </Paper>
    </div>
  );
};

export default InviteRegistrationForm;
