import React, { useState } from "react";
import {
  Paper,
  Typography,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import clsx from "clsx";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import config from "../../config";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import AvatarUpload from "../../components/AvatarUpload";
import MuiPhoneNumber from "material-ui-phone-number";
import CountrySelect from "../../components/Forms/CountrySelect";
import { useAppSelector } from "../../redux/hooks";

type SubmissionType = {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  address1: string;
  address2?: string;
  city?: string;
  state: string;
  country?: string;
  postalCode: string;
  password?: string;
  passwordConfirm?: string;
  passwordReset: boolean;
};

const useStyles = makeStyles((theme) => ({
  infoText: {
    textAlign: "center",
    fontStyle: "italic",
    maxWidth: "50%",
    margin: "20px auto 0 auto",
  },
  profileForm: {
    display: "flex",
    flexDirection: "column",
  },
  formPaper: {
    margin: "40px auto 40px auto",
    width: "98%",
    maxWidth: 600,
    padding: 30,
  },
  formField: {
    margin: 20,
  },
  alert: {
    width: "80%",
    margin: "20px auto",
  },
  noPhoneInput: {
    "&& .MuiInputAdornment-root": {
      display: "none",
    },
  },
}));

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("First name required"),
  lastName: Yup.string().required("Last name required"),
  email: Yup.string().email().required("Email is required"),
  phone: Yup.string(),
  address1: Yup.string(),
  address2: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  country: Yup.string(),
  postalCode: Yup.string(),
  password: Yup.string().min(5, "Password must be at least five characters."),
  passwordConfirm: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const ProfileForm: React.FC = () => {
  const classes = useStyles();
  const auth = useAppSelector((state) => state.auth);

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      firstName: auth.user?.first_name || "",
      lastName: auth.user?.last_name || "",
      email: auth.user?.email_address || "",
      phone: auth.user?.phone_number || "",
      address1: auth.user?.address_1 || "",
      address2: auth.user?.address_2 || "",
      city: auth.user?.city || "",
      state: auth.user?.state || "",
      country: auth.user?.country || "",
      postalCode: auth.user?.postal_code || "",
      password: "",
      passwordConfirm: "",
      passwordReset: false,
    },
    validationSchema: ProfileSchema,
    onSubmit: async (values: SubmissionType, { setSubmitting }) => {
      values["passwordReset"] = values.password ? true : false;
      await submitForm();
    },
  });

  const submitForm = async () => {
    return axios
      .put(
        `${config.API_URL}/users/${auth.user?.id}`,
        {
          firstName: formik.values["firstName"],
          lastName: formik.values["lastName"],
          email: formik.values["email"],
          phone: formik.values["phone"],
          address1: formik.values["address1"],
          address2: formik.values["address2"],
          city: formik.values["city"],
          state: formik.values["state"],
          country: formik.values["country"],
          postalCode: formik.values["postalCode"],
          password: formik.values["password"],
          passwordReset: formik.values["passwordReset"],
        },
        config.getAxiosJwtHeader()
      )
      .then((response) => {
        if (response.status === 200) {
          setIsSuccess(true);
        }
      })
      .catch((e) => {
        console.error(e.response);
        setIsError(true);
      });
  };

  return (
    auth && (
      <>
        <Typography className={classes.infoText}>
          Update your profile information below and submit to save changes.
        </Typography>
        <AvatarUpload />
        <Paper className={classes.formPaper}>
          <form onSubmit={formik.handleSubmit} className={classes.profileForm}>
            <Typography
              variant="h5"
              style={{ marginBottom: 20, textAlign: "center" }}
            >
              Contact Information
            </Typography>
            <TextField
              label="First Name"
              variant="outlined"
              id="firstName"
              name="firstName"
              className={classes.formField}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              id="lastName"
              name="lastName"
              className={classes.formField}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
            <TextField
              label="Email Address"
              variant="outlined"
              id="email"
              name="email"
              className={classes.formField}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <MuiPhoneNumber
              disableAreaCodes
              label="Phone Number"
              variant="outlined"
              id="phone"
              name="phone"
              className={clsx(
                classes.formField,
                !formik.values.phone && classes.noPhoneInput
              )}
              value={formik.values.phone}
              onChange={(value: string) => {
                formik.setFieldValue("phone", value);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
            <TextField
              label="Address 1"
              variant="outlined"
              id="address1"
              name="address1"
              className={classes.formField}
              value={formik.values.address1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address1 && Boolean(formik.errors.address1)}
              helperText={formik.touched.address1 && formik.errors.address1}
            />
            <TextField
              label="Address 2"
              variant="outlined"
              id="address2"
              name="address2"
              className={classes.formField}
              value={formik.values.address2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address2 && Boolean(formik.errors.address2)}
              helperText={formik.touched.address2 && formik.errors.address2}
            />
            <TextField
              label="City"
              variant="outlined"
              id="city"
              name="city"
              className={classes.formField}
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
            <TextField
              label="State/Region"
              variant="outlined"
              id="state"
              name="state"
              className={classes.formField}
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
            />
            <TextField
              label="Postal Code"
              variant="outlined"
              id="postalCode"
              name="postalCode"
              className={classes.formField}
              value={formik.values.postalCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.postalCode && Boolean(formik.errors.postalCode)
              }
              helperText={formik.touched.postalCode && formik.errors.postalCode}
            />
            <CountrySelect
              label="Country"
              variant="outlined"
              id="country"
              name="country"
              className={classes.formField}
              value={formik.values.country}
              onChange={(value: string) => {
                formik.setFieldValue("country", value);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
            />
            <Divider style={{ margin: 28 }} />
            <Typography
              variant="h5"
              style={{ marginBottom: 20, textAlign: "center" }}
            >
              Update Password
            </Typography>
            <TextField
              label="Password"
              variant="outlined"
              id="password"
              name="password"
              type="password"
              className={classes.formField}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              id="passwordConfirm"
              name="passwordConfirm"
              type="password"
              className={classes.formField}
              value={formik.values.passwordConfirm}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.passwordConfirm &&
                Boolean(formik.errors.passwordConfirm)
              }
              helperText={
                formik.touched.passwordConfirm && formik.errors.passwordConfirm
              }
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              className={classes.formField}
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? "Submitting..." : "Submit"}
            </Button>
            {isSuccess ? (
              <Alert color="success" className={classes.alert}>
                Success!
              </Alert>
            ) : isError ? (
              <Alert color="error" className={classes.alert}>
                An error has occurred.
              </Alert>
            ) : null}
          </form>
        </Paper>
      </>
    )
  );
};

export default ProfileForm;
